import { ArticleModel } from "../../models/articleModel";
import React from "react";
import styled from "styled-components";
import { justifyContentBetween, alignItemsCenter } from "../../styles/bootstrap";
import { bgWhite, borderGray300, TextSmall, TextExtraSmall } from "../../styles/theme";
import { fontAlt, fontWeight600 } from "../../styles/fonts";
import { LetterSpacing1 } from "../../styles/letter-spacing";
import { gray800 } from "../../styles/colors";
import { NewsFeedSkeleton } from "./NewsFeed.skeleton";

type Props = {
  $articles?: ArticleModel[];
};

export class NewsFeed extends React.Component<Props> {
  render() {
    const { $articles } = this.props;

    if (!$articles) {
      return <NewsFeedSkeleton />;
    }

    return $articles && $articles.map((m, index) =>
      <IndividualMedia key={index} $index={index} {...m} />
    );
  }
}

type Indexed = {
  $index: number;
};
const IndividualMedia = (props: ArticleModel & Indexed) => {
  const { $index, link, title, description, publisherLogo, publisherName, publisherLink, cover } = props;
  return (
    <div className="col-md-6 col-xl-6">
      <StyledIndividualMedia $index={$index}>
        <div className="pr-md-4">
          <span className="title">
            <a href={link}>{title}</a>
          </span>
          <span className="content">{description}</span>
          <div className="publisher">
            <img className="publisher-logo" src={publisherLogo} />
            <a href={publisherLink}>{publisherName}</a>
          </div>
        </div>
        <img src={cover} alt={title} />
      </StyledIndividualMedia>
    </div>
  );
}

type StyledIndividualMediaProps = {
  $index: number;
};
const StyledIndividualMedia = styled.div<StyledIndividualMediaProps>`
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05) !important;
  display: flex;
  margin-top: 1.5rem;
  padding: 1.5rem;
  position: relative;
  border-radius: .25rem;
  border: 1px solid #dee2e6;
  ${justifyContentBetween}
  ${alignItemsCenter}
  ${bgWhite}
  ${borderGray300};
  
  .title {
    display: block;
    text-transform: uppercase;
    font-family: ${fontAlt};
    ${fontWeight600}
    ${LetterSpacing1}
    ${TextSmall}
  }

  .content {
    display: block;
    margin-top: .5rem;
    color: ${gray800};
    font-family: ${fontAlt};
    ${TextExtraSmall}
    ${fontWeight600}
    ${LetterSpacing1}
  }

  .publisher {
    margin-top: .5rem;
    a {
      text-transform: uppercase;
      vertical-align: top;
      ${TextSmall}
    }
    .publisher-logo {
      width: 20px;
      float: left;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  img {
    max-width: 100%;
    width: 100px;
    height: auto;
    border-radius: .25rem;
    display: none;
    @media (min-width: 768px) {
      display: block !important;
    }
  }
`;
