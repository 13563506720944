type FrequentlyAskedQuestionModelProps = {
  question: string;
  answer: string;
}

export class FrequentlyAskedQuestionModel {
  question: string;
  answer: string;

  constructor(props: FrequentlyAskedQuestionModelProps) {
    this.question = props.question;
    this.answer = props.answer;
  }
};
