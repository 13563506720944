import styled, { css } from "styled-components";
import { WithinQuery, Media } from "../../styles/mediaQuery";
import { gray200 } from "../../styles/colors";
import { SeparatorLine } from "../../styles/sep-line";
import { skeletonDiv, SkeletonColorShade } from "../../styles/skeleton";

const skeletonColorShade = SkeletonColorShade.Darker;

type IndividualStatisticProps = {
  index: number;
};

export const IndividualStatisticSkeleton = (props: IndividualStatisticProps) => {
  return (
    <StyledIndividualStatistic
      $isLast={props.index > 0 && props.index % 3 === 0}
      $isMiddle={props.index % 1 === 0 || props.index % 2 === 0}
    >
      <div className="number" />
      <SeparatorLine $thickness="thin" $length="long" />
      <div className="name" />
    </StyledIndividualStatistic>
  );
};

type StyledIndividualStatisticProps = {
  $isMiddle: boolean;
  $isLast: boolean;
};

const StyledIndividualStatistic = styled.div.attrs({ className: "col-md-3" }) <StyledIndividualStatisticProps>`
  text-align: center;
  
  ${props =>
    !props.$isLast &&
    css`
      border-color: ${gray200}
        ${WithinQuery(
      Media.MD,
      css`
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          `
    )};
    `}

  ${props =>
    props.$isMiddle &&
    css`
      margin-top: 3rem;
      ${WithinQuery(
      Media.MD,
      css`
          margin-top: 0;
        `
    )}
    `}

  ${SeparatorLine} {
    ${skeletonDiv(skeletonColorShade)}
    margin-top: 1rem;
    margin-right: auto;
    margin-left: auto;
  }
  
  .number {
    ${skeletonDiv(skeletonColorShade)}
    width: 150px;
    height: 54px;
    margin-left: auto;
    margin-right: auto;
  }

  .name {
    ${skeletonDiv(skeletonColorShade)}
    display: block;
    margin-top: 1rem;
    width: 170px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
  }
`;
