import React from "react";
import styled, { css } from "styled-components";
import LogoLutinWhite from "../assets/lutin-white.png";
import LogoLutinBlack from "../assets/lutin-black.png";
import NavBarTogglerLight from "../assets/navbar-toggler-light.svg";
import { white, black } from "../styles/colors";
import { WithinQuery, Media } from "../styles/mediaQuery";
import {
  Navbar,
  Container,
  Row,
  NavbarBrand,
  Image,
  NavItem,
  NavLink,
  Nav,
  Collapse,
  Button,
} from "react-bootstrap";
import intl from "react-intl-universal";
import { AppSections } from "../App";
import { removeTextSelection } from "../styles/globalStyles";
import { StyledButton } from "../styles/buttons";
import { Anouncement } from "./Anouncement";

type OwnProps = {
  $isShrinked: boolean;
  children: React.ReactNode;
  scrollToContent(componentName: AppSections): void;
};

type StateProps = {
  isOpen: boolean;
};

export class Header extends React.Component<OwnProps, StateProps> {
  constructor(props: OwnProps) {
    super(props);
    this.state = { isOpen: window.innerWidth >= 992 };
    window.onresize = () => this.resized();
  }

  toggleNavigation() {
    this.setState({ isOpen: !this.state.isOpen });
    const header = document.getElementById("navbar-header");
    if (header == null) {
      return;
    }
    header.style.display = "";
  }

  resized() {
    const header = document.getElementById("navbar-header");
    if (header == null) {
      return;
    }

    if (window.innerWidth >= 992 && !this.state.isOpen) {
      header.style.visibility = "collapse";
      this.setState({ isOpen: true });
      header.style.display = "inline";
      setTimeout(() => {
        header.style.visibility = "visible";
      }, 300);
    } else if (window.innerWidth < 992 && this.state.isOpen) {
      header.style.display = "none";
      this.setState({ isOpen: false });
    }
  }

  render() {
    const scrollIntoToTop = () => this.props.scrollToContent(AppSections.ToTop);
    const scrollIntoAbout = () => this.props.scrollToContent(AppSections.About);
    const scrollIntoSchools = () =>
      this.props.scrollToContent(AppSections.Schools);
    const scrollIntoHistory = () =>
      this.props.scrollToContent(AppSections.Timeline);
    const scrollIntoVolunteering = () =>
      this.props.scrollToContent(AppSections.VolunteerApplication);
    const scrollIntoContribute = () =>
      this.props.scrollToContent(AppSections.Contribute);
    const scrollIntoTimeline = () =>
      this.props.scrollToContent(AppSections.Timeline);
    const scrollIntoMedia = () =>
      this.props.scrollToContent(AppSections.MediaGallerry);
    const scrollIntoFAQs = () =>
      this.props.scrollToContent(AppSections.FrequentlyAskedQuestions);
    const scrollIntoContactUs = () =>
      this.props.scrollToContent(AppSections.ContactUs);
    return (
      <>
        <Navbar expand="false" variant="">
          <StyledHeader className="fixed-top" $isShrink={this.props.$isShrinked}>

            <Container>
              <Row>
                <div className="col-lg-3 navbar-header">
                  <NavbarBrand className="page-scoll">
                    <Image
                      className="logo-dark"
                      src={LogoLutinWhite}
                      onClick={scrollIntoToTop}
                      style={{ cursor: "pointer" }}
                    />
                    <Image
                      className="logo-light"
                      src={LogoLutinBlack}
                      onClick={scrollIntoToTop}
                      style={{ cursor: "pointer" }}
                    />
                  </NavbarBrand>
                  <Navbar.Toggle
                    aria-label="Toggle navigation"
                    onClick={() => this.toggleNavigation()}
                    aria-expanded={this.state.isOpen}
                    data-target="navbar-header"
                    aria-controls="navbar-header"
                  >
                    <span className="navbar-toggler-icon" />
                  </Navbar.Toggle>
                </div>
                <div className="col-lg-6 navbar-wrapper justify-content-center">
                  <div className="navbar navbar-expand-lg">
                    <Nav navbar={true}>
                      <Collapse in={this.state.isOpen}>
                        <div id="navbar-header">
                          <Nav navbar={true} style={{ marginTop: "0px" }}>
                            <NavItem>
                              <NavLink onClick={scrollIntoAbout}>
                                {intl.get("common.about")}
                              </NavLink>
                            </NavItem>
                            {/* <NavItem>
                            <NavLink onClick={scrollIntoSchools}>
                              {intl.get("common.school")}
                            </NavLink>
                          </NavItem> */}
                            <NavItem>
                              <NavLink onClick={scrollIntoContribute}>
                                {intl.get("common.contribute")}
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink onClick={scrollIntoHistory}>
                                {intl.get("common.timeline")}
                              </NavLink>
                            </NavItem>
                            {/* <NavItem>
                            <NavLink onClick={scrollIntoVolunteering}>
                              {intl.get("common.volunteer")}
                            </NavLink>
                          </NavItem> */}

                            <NavItem>
                              <NavLink onClick={scrollIntoTimeline}>
                                {intl.get("common.schedule")}
                              </NavLink>
                            </NavItem>
                            <NavItem className="align-items-center d-flex h-100">
                              <NavLink onClick={scrollIntoMedia}>
                                {intl.get("common.media")}
                              </NavLink>
                            </NavItem>
                            {/*<NavItem>
                            <NavLink onClick={scrollIntoFAQs}>
                              {intl.get("common.faq")}
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <div
                              className="navbar-nav"
                              style={{ marginTop: "0px" }}
                            >
                              <div
                                className="language-switcher-wrapper"
                                style={{ paddingTop: "1px" }}
                              >
                                <label className="language-switcher">
                                  {this.props.children}
                                </label>
                              </div>
                            </div>
                          </NavItem> */}
                          </Nav>
                        </div>
                      </Collapse>
                    </Nav>
                  </div>
                </div>
                <div
                  className="col-lg-3 d-none d-lg-block pe-lg-0"
                  style={{ justifyContent: "right" }}
                >
                  <div className="align-items-center d-flex h-100 justify-content-end">
                    <StyledButton
                      $themeColor="secondary"
                      $buttonSize="extraSmall"
                      $lgSize="extraSmall"
                      className="page-scroll"
                      onClick={scrollIntoContactUs}
                    >
                      {intl.get("common.contactUs")}
                    </StyledButton>
                  </div>
                </div>
              </Row>
              <Row>


                <Anouncement />
              </Row>
            </Container>
          </StyledHeader>
        </Navbar>
      </>
    );
  }
}

interface StyledHeaderProps {
  $isShrink: boolean;
}

// TODO: make this dynamic with announcement
//const HeaderHeight = 80;
//const ShrinkHeaderHeight = 60;
const TotalHeaderHeight = 80;
const HeaderHeight = 80;
const TotalHeaderShrinkedHeight = 60;
const HeaderShrinkedHeight = 60;

/*
& .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    position: absolute;
    right: 15px;
    top: 20px;
  }
*/

const StyledHeader = styled.header<StyledHeaderProps>`
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  height: ${TotalHeaderHeight}px;
  width: 100%;
  z-index: 1020;

  &,
  & .navbar-header,
  & .navbar-brand img {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  & .navbar-nav {
    margin-top: 8px;
    .nav-item {
      text-align: left;
      .nav-link {
        font-family: "Montserrat", sans-serif;
        white-space: nowrap;
        font-size: 0.6875rem;
        font-weight: 600;
        letter-spacing: 0.09375rem;
        position: relative;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.9);
        cursor: pointer;
        ${removeTextSelection}

        &:hover {
          color: ${white};
        }
      }
    }

    .language-switcher-wrapper {
      padding: 0.386rem 0.9375rem;

      .language-switcher {
        margin-bottom: 0rem;
        position: relative;

        ${WithinQuery(
  Media.LG,
  css`
            top: -6px;
          `
)};

        select {
          @media screen and (-webkit-min-device-pixel-ratio: 0) {
            padding-right: 13px;
          }
          font-family: "Montserrat", sans-serif;
          font-size: 0.6875rem;
          font-weight: 600;
          -webkit-letter-spacing: 0.09375rem;
          -moz-letter-spacing: 0.09375rem;
          -ms-letter-spacing: 0.09375rem;
          letter-spacing: 0.09375rem;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.9);
          border: none;
          background: none;
          outline: none;
          display: inline-block;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          cursor: pointer;
          overflow: hidden;
          ${removeTextSelection}
          option {
            background: rgba(0, 0, 0, 0.3);
            margin-right: 5px;
            font-weight: 600;
          }
        }
      }
      .language-switcher:after {
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        margin-left: 2px;
        color: rgba(255, 255, 255, 0.9);
        pointer-events: none;
        font-weight: 700 !important;
        position: absolute;
        right: 0;
        top: 1px;
      }
    }
  }

  & .navbar {
    padding: 0;
  }

  & .navbar-header {
    height: ${HeaderHeight}px;
  }

  & .navbar-brand {
    display: block;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      left: 0;
      height: 46px;
      top: 17px;
    }
  }

  & .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    position: absolute;
    right: 15px;
    top: 20px;
  }

  & .navbar-toggler-icon {
    background-image: url(${NavBarTogglerLight});
  }
  background-color: rgba(255, 255, 255, 0.98);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);

  & .navbar-wrapper {
    background-color: rgba(255, 255, 255, 0.98);
  }

  & .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.5);
  }

  @media (max-width: 991.98px) {
    height: ${TotalHeaderHeight}px;
    & .navbar-brand img {
      height: 46px;
      top: 17px;
    }
    & .container {
      max-width: none;
    }
    & .navbar {
      width: 100%;
    }
    & .navbar-wrapper {
      min-height: 0;
      padding-left: 0;
      padding-right: 0;
    }
    & .navbar-collapse {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }

    & .navbar-nav .nav-link {
      padding: 0.625rem 0.9375rem;
    }

    & .navbar-brand {
      & .logo-light {
        opacity: 1;
      }
      & .logo-dark {
        opacity: 0;
      }
    }

    & .navbar-nav {
      margin-top: 0;
      .nav-item {
        .nav-link {
          color: rgba(0, 0, 0, 0.9) !important;
          &.active:before {
            background-color: ${black} !important;
          }
          &:hover {
            color: ${black} !important;
          }
        }
      }

      .language-switcher-wrapper {
        .language-switcher {
          select {
            color: rgba(0, 0, 0, 0.9);
            option {
              background: rgba(0, 0, 0, 0.3);
              margin-right: 5px;
            }
          }
        }

        .language-switcher:after {
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }

  @media (min-width: 992px) {
    background-color: rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    & .navbar-brand {
      & .logo-light {
        opacity: 0;
      }
      & .logo-dark {
        opacity: 1;
      }
    }
    & .navbar-wrapper {
      background-color: transparent;
      display: flex;
    }
    & .navbar-toggler {
      display: none;
    }
    & .navbar-nav .nav-item {
      padding: 0 0.875rem !important;
    }
    & .navbar-nav {
      .nav-link {
        color: rgba(255, 255, 255, 0.9);
        padding: 0 !important;
        &:before {
          bottom: 0;
          content: "";
          display: block;
          height: 1px;
          left: 0;
          margin-bottom: -3px;
          position: absolute;
          width: 100%;
        }
        &:hover {
          color: ${white} !important;
        }
        &.active:before {
          background-color: ${white} !important;
        }
      }
    }
  }

  ${(props) =>
    props.$isShrink &&
    css`
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      height: ${TotalHeaderShrinkedHeight}px;

      & .navbar-header {
        height: ${HeaderShrinkedHeight}px;
      }

      & .navbar-brand img {
        height: 40px;
        top: 10px;
      }
      & .navbar-brand {
        & .logo-light {
          opacity: 1;
        }
        & .logo-dark {
          opacity: 0;
        }
      }
      & .navbar-nav {
        .nav-item .nav-link {
          color: rgba(0, 0, 0, 0.9);
          padding: 0.625rem 0.9375rem;
          &:before {
            bottom: 0;
            content: "";
            display: block;
            height: 1px;
            left: 0;
            margin-bottom: -3px;
            position: absolute;
            width: 100%;
          }
          &:hover {
            color: ${black} !important;
          }
          &.active:before {
            background-color: ${black} !important;
          }
        }

        .language-switcher-wrapper {
          .language-switcher {
            select {
              color: rgba(0, 0, 0, 0.9);
              option {
                background: rgba(0, 0, 0, 0.3);
                margin-right: 5px;
              }
            }
          }

          .language-switcher:after {
            color: rgba(0, 0, 0, 0.9);
          }
        }
      }

      @media (min-width: 992px) {
        color: rgba(0, 0, 0, 0.9);
        background-color: rgba(255, 255, 255, 0.98) !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      }

      @media (max-width: 991.98px) {
        height: ${TotalHeaderHeight}px;
        & .navbar-header {
          height: ${HeaderHeight}px;
        }

        & .navbar-brand img {
          height: 46px;
          top: 17px;
        }
      }
    `};
`;
