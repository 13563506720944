export const black = "#000000";
export const white = "#FFFFFF";
export const gray100 = "#f8f9fa";
export const gray200 = "#e9ecef";
export const gray300 = "#dee2e6";
export const gray400 = "#ced4da";
export const gray500 = "#adb5bd";
export const gray600 = "#868e96";
export const gray700 = "#495057";
export const gray800 = "#343a40";
export const gray900 = "#212529";
export const gray1 = "#ececec";
export const gray2 = "#e6e6e6";
export const gray3 = "#dfdfdf";

export const skeletonColorLight = "#f6f6f6";
export const skeletonColorMedium = "#e9ecef";
export const skeletonColorDark = "#868e96";
export const skeletonColorDarker = "#495057";

export const blue = "#4d64bf";
export const green = "#63b02e";
export const orange = "#f59f50";
export const pink = "#ec94b6";
export const red = "#ea553d";
export const yellow = "#ffbb44";

// Theme
export const primary = "#36bcd1";
export const primaryHoverBg = "#2ca0b4"; // slightly darkened background
export const primaryHoverBorder = "#27879d"; // slightly darkened border
export const primaryActiveBg = "#228593"; // more darkened background
export const primaryActiveBorder = "#1e7682"; // more darkened border
export const primaryBoxShadow = "rgba(54, 188, 209, 0.5)";

export const secondary = "#ff9045";
export const secondaryHoverBg = "#e87e33"; // slightly darkened background
export const secondaryHoverBorder = "#e2742b"; // slightly darkened border
export const secondaryActiveBg = "#d96e23"; // more darkened background
export const secondaryActiveBorder = "#c8621d"; // more darkened border
export const secondaryBoxShadow = "rgba(255, 144, 69, 0.5)";
