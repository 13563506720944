import { SeparatorLine } from "../../styles/sep-line";
import styled, { css } from "styled-components";
import { WithinQuery, Media } from "../../styles/mediaQuery";
import { gray200, white } from "../../styles/colors";
import { fontAlt, fontWeight700, fontWeight600 } from "../../styles/fonts";
import { LetterSpacing1, LetterSpacing2 } from "../../styles/letter-spacing";
import { TitleExtraLarge2, TextLarge, bgPrimary } from "../../styles/theme";
import CountUp from "react-countup";

const CountToSpeed: number = 3.75;

type IndividualStatisticProps = {
  $index: number;
  $statistic: {
    $name: string;
    $value: number;
  };
  $emptyValue: string;
};

export const IndividualStatistic = (props: IndividualStatisticProps) => {
  const { $index, $statistic, $emptyValue } = props;
  const { $name, $value } = $statistic;
  return (
    <StyledIndividualStatistic
      $isLast={$index > 0 && $index % 3 === 0}
      $isMiddle={$index % 1 === 0 || $index % 2 === 0}
    >
      <span className="statistic">
        {$value === -1 && <>{$emptyValue}</>}
        {$value !== -1 && (
          <CountUp duration={CountToSpeed} useEasing={true} end={$value} />
        )}
      </span>
      <SeparatorLine $thickness="thick" $length="long" />
      <span className="name">{$name}</span>
    </StyledIndividualStatistic>
  );
};

type StyledIndividualStatisticProps = {
  $isMiddle: boolean;
  $isLast: boolean;
};

const StyledIndividualStatistic = styled.div.attrs({
  className: "col-md-4",
})<StyledIndividualStatisticProps>`
  text-align: center;
  position: relative;
  ${(props) =>
    !props.$isLast &&
    css`
      border-color: ${gray200}
        ${WithinQuery(
          Media.MD,
          css`
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          `
        )};
    `}

  ${(props) =>
    props.$isMiddle &&
    css`
      margin-top: 3rem;
      ${WithinQuery(
        Media.MD,
        css`
          margin-top: 0;
        `
      )}
    `}

  .statistic {
    display: block;
    font-family: ${fontAlt};
    color: ${white};
    ${fontWeight700}
    ${LetterSpacing1}
    ${TitleExtraLarge2}
  }

  ${SeparatorLine} {
    ${bgPrimary}
    margin-top: .5rem;
    margin-right: auto;
    margin-left: auto;
  }

  .name {
    display: block;
    margin-top: 1rem;
    opacity: 0.7;
    text-transform: uppercase;
    font-family: ${fontAlt};
    color: ${white};
    ${TextLarge}
    ${fontWeight600}
    ${LetterSpacing2}
  }
`;
