import { skeletonColorLight, skeletonColorMedium, skeletonColorDark, skeletonColorDarker } from "./colors";
import { css } from "styled-components";

export const skeletonDiv = (shade: SkeletonColorShade) => css`
  border-radius: 0.4rem;
  background-color: ${skeletonColor(shade)};
`;

export enum SkeletonColorShade {
  Light,
  Medium,
  Dark,
  Darker
}

export const skeletonColor = (shade: SkeletonColorShade) => {
  switch (shade) {
    case SkeletonColorShade.Light:
      return skeletonColorLight;
    case SkeletonColorShade.Medium:
      return skeletonColorMedium;
    case SkeletonColorShade.Dark:
      return skeletonColorDark;
    case SkeletonColorShade.Darker:
      return skeletonColorDarker;
  }
};
