type FormModelProps = {
  elf: string;
  eventVolunteer: string;
  funding: string;
}

export class FormModel {
  elf: string;
  eventVolunteer: string;
  funding: string;

  constructor(props: FormModelProps) {
    this.elf = props.elf;
    this.eventVolunteer = props.eventVolunteer;
    this.funding = props.funding;
  }
};
