import styled from "styled-components";
import React, { HTMLAttributes } from "react";
import { SeparatorLine } from "../../styles/sep-line";
import { justifyContentCenter } from "../../styles/bootstrap";
import { fontAlt, fontWeight600, fontWeight700 } from "../../styles/fonts";
import { LetterSpacing1, LetterSpacing2 } from "../../styles/letter-spacing";
import { bgPrimary, TextSmall, TitleSmall } from "../../styles/theme";
import { gray800 } from "../../styles/colors";
import { removeTextSelection } from "../../styles/globalStyles";

type OwnProps = {
  $title: string;
  $subtitle: string;
};

type Props = HTMLAttributes<"div"> & OwnProps;

const createMarkup = (text: string) => {
  return { __html: text };
};

export const SectionHeader = (props: Props) => (
  <StyledSectionHeader className={props.className}>
    <div className="col-lg-7">
      <h3 dangerouslySetInnerHTML={createMarkup(props.$title)}></h3>
      <span
        className="subtitle"
        dangerouslySetInnerHTML={createMarkup(props.$subtitle)}
      ></span>
      <SeparatorLine $thickness="medium-thick" $length="long" />
    </div>
  </StyledSectionHeader>
);

const StyledSectionHeader = styled.div`
  margin-bottom: 3rem;
  ${justifyContentCenter}

  div.col-lg-7 {
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 992px) {
      margin-bottom: 3rem;
    }

    h3 {
      font-family: ${fontAlt};
      ${fontWeight700}
      ${LetterSpacing2}
      margin: 0px;
      ${TitleSmall}
      text-transform: uppercase;
    }

    span.subtitle {
      display: block;
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-family: ${fontAlt};
      color: ${gray800};
      ${fontWeight600}
      ${LetterSpacing1}
      ${TextSmall}
    }

    h3,
    span.subtitle {
      ${removeTextSelection}
    }
  }

  ${SeparatorLine} {
    ${bgPrimary}
    display: block;
    margin-top: 1.5rem;
    margin-right: auto;
    margin-left: auto;
  }
`;
