import { bgGrayOverlay7, bgImgCover } from "../../styles/theme";
import styled from "styled-components";
import { IndividualStatisticSkeleton } from "./IndividualStatistic.skeleton";

export const StatisticsSkeleton = () => (
  <StyledStatisticsSkeleton>
    <div className="container">
      <div className="row">
        <IndividualStatisticSkeleton key={0} index={0} />
        <IndividualStatisticSkeleton key={1} index={1} />
        <IndividualStatisticSkeleton key={2} index={2} />
        <IndividualStatisticSkeleton key={3} index={3} />
      </div>
    </div>
  </StyledStatisticsSkeleton>
);

const StyledStatisticsSkeleton = styled.div`
  ${bgGrayOverlay7}
  ${bgImgCover}

  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
`;
