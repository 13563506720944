import { css } from "styled-components";
import {
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray700,
  gray800,
  gray900,
  black,
  white,
  blue,
  green,
  orange,
  pink,
  red,
  yellow,
  primary,
  secondary,
} from "./colors";

/*
 * Common RGB Values
 */
type RGB = {
  r: number;
  g: number;
  b: number;
};
const blackRgb: RGB = { r: 0, g: 0, b: 0 };
const greyRgb: RGB = { r: 37, g: 37, b: 37 };

export const propToColor = (color: string) => {
  switch (color) {
    case "black":
      return black;
    case "white":
      return white;
    case "gray-100":
      return gray100;
    case "gray-200":
      return gray200;
    case "gray-300":
      return gray300;
    case "gray-400":
      return gray400;
    case "gray-500":
      return gray500;
    case "gray-600":
      return gray600;
    case "gray-700":
      return gray700;
    case "gray-800":
      return gray800;
    case "gray-900":
      return gray900;
    case "blue":
      return blue;
    case "green":
      return green;
    case "orange":
      return orange;
    case "pink":
      return pink;
    case "red":
      return red;
    case "yellow":
      return yellow;
  }
};

/*
 * Background Color
 */
const BackgroundColor = (color: string) =>
  `background-color: ${color} !important;`;
export const bgGray100 = BackgroundColor(gray100);
export const bgGray200 = BackgroundColor(gray200);
export const bgGray300 = BackgroundColor(gray300);
export const bgGray400 = BackgroundColor(gray400);
export const bgGray500 = BackgroundColor(gray500);
export const bgGray600 = BackgroundColor(gray600);
export const bgGray700 = BackgroundColor(gray700);
export const bgGray800 = BackgroundColor(gray800);
export const bgGray900 = BackgroundColor(gray900);
export const bgBlack = BackgroundColor(black);
export const bgWhite = BackgroundColor(white);
export const bgBlue = BackgroundColor(blue);
export const bgGreen = BackgroundColor(green);
export const bgOrange = BackgroundColor(orange);
export const bgPink = BackgroundColor(pink);
export const bgRed = BackgroundColor(red);
export const bgYellow = BackgroundColor(yellow);
export const bgPrimary = BackgroundColor(primary);
export const bgSecondary = BackgroundColor(secondary);

/*
 * Background Image
 */
const backgroundImage = css`
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative;
`;
export const bgImgCover = backgroundImage;

export const bgImgParallax = css`
  ${backgroundImage}
  height: 150%;
  overflow: hidden;
  width: 100%;
  top: -75%;
`;

/*
 * Background Overlay
 */
const bgOverlay = (rgb: RGB, alpha: number) => css`
  position: relative;
  &:before {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha}) !important;
  }
`;

export const bgBlackOverlay1 = bgOverlay(blackRgb, 0.1);
export const bgBlackOverlay2 = bgOverlay(blackRgb, 0.2);
export const bgBlackOverlay3 = bgOverlay(blackRgb, 0.3);
export const bgBlackOverlay4 = bgOverlay(blackRgb, 0.4);
export const bgBlackOverlay5 = bgOverlay(blackRgb, 0.5);
export const bgBlackOverlay6 = bgOverlay(blackRgb, 0.6);
export const bgBlackOverlay7 = bgOverlay(blackRgb, 0.7);
export const bgBlackOverlay8 = bgOverlay(blackRgb, 0.8);
export const bgBlackOverlay9 = bgOverlay(blackRgb, 0.9);

export const bgGrayOverlay1 = bgOverlay(greyRgb, 0.1);
export const bgGrayOverlay2 = bgOverlay(greyRgb, 0.2);
export const bgGrayOverlay3 = bgOverlay(greyRgb, 0.3);
export const bgGrayOverlay4 = bgOverlay(greyRgb, 0.4);
export const bgGrayOverlay5 = bgOverlay(greyRgb, 0.5);
export const bgGrayOverlay6 = bgOverlay(greyRgb, 0.6);
export const bgGrayOverlay7 = bgOverlay(greyRgb, 0.7);
export const bgGrayOverlay8 = bgOverlay(greyRgb, 0.8);
export const bgGrayOverlay9 = bgOverlay(greyRgb, 0.9);

/*
 * Border
 */
type BorderProps = {
  $top?: boolean;
  $bottom?: boolean;
  $left?: boolean;
  $right?: boolean;
  $thickness?: "thick" | "medium-thickness" | "extra-thickness";
};
const borderStyle = `1px solid rgba(${blackRgb.r}, ${blackRgb.g}, ${blackRgb.b}, 0.1)`;
export const border = (props: BorderProps) => css`
  ${props.$top && `border-top: ${borderStyle}`}
  ${props.$bottom && `border-bottom: ${borderStyle}`}
  ${props.$left && `border-left: ${borderStyle}`}
  ${props.$right && `border-right: ${borderStyle}`}
  ${props.$thickness &&
  props.$thickness === "thick" &&
  "border-width: 2px !important;"}
  ${props.$thickness &&
  props.$thickness === "medium-thickness" &&
  "border-width: 3px !important;"}
  ${props.$thickness &&
  props.$thickness === "extra-thickness" &&
  "border-width: 4px !important;"}
`;

/*
 * Border Color
 */
const borderColor = (color: string) =>
  css`
    border-color: ${color} !important;
  `;
export const borderGray100 = borderColor(gray100);
export const borderGray200 = borderColor(gray200);
export const borderGray300 = borderColor(gray300);
export const borderGray400 = borderColor(gray400);
export const borderGray500 = borderColor(gray500);
export const borderGray600 = borderColor(gray600);
export const borderGray700 = borderColor(gray700);
export const borderGray800 = borderColor(gray800);
export const borderGray900 = borderColor(gray900);

export const borderBlack = borderColor(black);
export const borderWhite = borderColor(white);
export const borderBlue = borderColor(blue);
export const borderGreen = borderColor(green);
export const borderOrange = borderColor(orange);
export const borderPink = borderColor(pink);
export const borderRed = borderColor(red);
export const borderYellow = borderColor(yellow);

/*
 * Box Shadow
 */
export const shadowLow = css`
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05) !important;
`;

export const shadowMedium = css`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
`;

export const shadowHigh = css`
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2) !important;
`;

/*
 * Text size
 */
const TextSize = (fontSize: number, lineHeight: number) => css`
  font-size: ${fontSize}rem !important;
  line-height: ${lineHeight}rem !important;
`;
export const TextExtraSmall = TextSize(0.6875, 1);
export const TextSmall = TextSize(0.875, 1.3125);
export const TextMedium = TextSize(1, 1.5);
export const TextLarge = TextSize(1.125, 1.6875);
export const TextExtraLarge = TextSize(1.25, 1.6875);
export const TitleSmall = TextSize(1.375, 1.875);
export const TitleMedium = TextSize(1.5, 2.125);
export const TitleLarge = TextSize(1.75, 2.375);
export const TitleExtraLarge = TextSize(2.25, 2.875);
export const TitleExtraLarge2 = TextSize(2.75, 3.375);
export const TitleExtraLarge3 = TextSize(3.125, 3.75);
export const TitleExtraLarge4 = TextSize(4.375, 5);
export const TitleExtraLarge5 = TextSize(5, 5.625);
export const TitleBig = TextSize(6.25, 6.25);
export const TitleBig2 = TextSize(7.75, 7.75);
export const TitleBig3 = TextSize(9.375, 9.375);
