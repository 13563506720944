type ArticleModelProps = {
  title: string;
  description: string;
  link: string;
  cover: string;
  publisherName: string;
  publisherLink: string;
  publisherLogo: string;
}

export class ArticleModel {
  title: string;
  description: string;
  link: string;
  cover: string;
  publisherName: string;
  publisherLink: string;
  publisherLogo: string;

  constructor(props: ArticleModelProps) {
    this.title = props.title;
    this.description = props.description;
    this.link = props.link;
    this.cover = props.cover;
    this.publisherName = props.publisherName;
    this.publisherLink = props.publisherLink;
    this.publisherLogo = props.publisherLogo;
  }
};
