import React, { HTMLAttributes } from "react";
import styled from "styled-components";
import { justifyContentCenter } from "../../styles/bootstrap";
import { skeletonColor, SkeletonColorShade } from "../../styles/skeleton";

type Props = HTMLAttributes<"div"> & {
  $shade: SkeletonColorShade;
  $inverseTextWidth: boolean;
};

export const SectionHeaderSkeleton = (props: Props) => (
  <StyledSectionHeaderSkeleton
    className={props.className}
    $shade={props.$shade}
    $inverseTextWidth={props.$inverseTextWidth}
  >
    <div className="col-lg-6">
      <div className="title" />
      <div className="subtitle" />
    </div>
  </StyledSectionHeaderSkeleton>
);

const StyledSectionHeaderSkeleton = styled.div<Props>`
  margin-bottom: 3rem;
  ${justifyContentCenter}

  div.col-lg-6 {
    text-align: center;
    margin-bottom: 1rem;
    @media (min-width: 992px) {
      margin-bottom: 3rem;
    }

    .title {
      margin: 0px;
      height: 25px;
      margin: 0px;
      border-radius: 1rem;
      margin-left: auto;
      margin-right: auto;
      width: ${props => (!props.$inverseTextWidth ? "75%" : "50%")};
      background: ${props => skeletonColor(props.$shade)};
    }

    .subtitle {
      display: block;
      margin-top: 1rem;
      margin-bottom: 2rem;
      height: 15px;
      width: ${props => (!props.$inverseTextWidth ? "50%" : "75%")};
      border-radius: 1rem;
      margin-left: auto;
      margin-right: auto;
      background: ${props => skeletonColor(props.$shade)};
    }
  }
`;
