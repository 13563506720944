import React, { ReactNode } from "react";
import { Section } from "../../styles/section";
import styled from "styled-components";
import { white } from "../../styles/colors";
import { justifyContentCenter } from "../../styles/bootstrap";
import { SectionHeader } from "../sectionHeader/SectionHeader";
import { SponsorModel } from "../../models/sponsorModel";
import { SponsorsSkeleton } from "./Sponsors.skeleton";
import { IndividualSponsor } from "./IndividualSponsor";
import intl from "react-intl-universal"

type Props = {
  $sponsors?: SponsorModel[];
};

export class Sponsors extends React.PureComponent<Props> {

  render() {
    const { $sponsors } = this.props;

    if (!$sponsors) {
      return <SponsorsSkeleton />;
    }

    return (
      <StyledSponsors>
        <div className="container">
          <SectionHeader
            className="row"
            $title={intl.get("landing.sponsorTitle")}
            $subtitle={intl.get("landing.sponsorSubTitle")}
          />
          <div className="row sponsors-content">
            <div className="col-lg-10">
              <div className="row">
                {$sponsors.map((s: SponsorModel, index: number) => (
                  <IndividualSponsor key={s.id} $index={index} $name={s.name} $logo={s.logo} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </StyledSponsors>
    );
  }
}

const StyledSponsors = styled(Section)`
  background-color: ${white};

  .sponsors-content {
    ${justifyContentCenter}
  }
`;
