import { css } from "styled-components";

// letter-spacing-3: 0.1875rem
// letter-spacing-4: 0.25rem
// letter-spacing-5: 0.3125rem
// letter-spacing-6: 0.375rem
// letter-spacing-7: 0.4375rem
// letter-spacing-8: 0.5rem
// letter-spacing-9: 0.5625rem
// letter-spacing-10: 0.625rem
// no-letter-spacing: 0

const LetterSpacing = (spacing: number) => css`letter-spacing: ${spacing}rem !important;`
export const LetterSpacing1 = LetterSpacing(0.0625);
export const LetterSpacing2 = LetterSpacing(0.125);