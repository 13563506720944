import styled from "styled-components";
import React from "react";
import { bgImgCover, bgGrayOverlay7 } from "../../styles/theme";
import { IndividualStatistic } from "./IndividualStatistic";
import StatisticsBg from "../../assets/statistics.jpg";
import { StatisticModel } from "../../models/statisticModel";
import { StatisticsSkeleton } from "./Statistics.skeleton";
import { white } from "../../styles/colors";
import intl from "react-intl-universal";

type Props = {
  $landingPageYear?: number;
  $statistics?: StatisticModel;
};

export class Statistics extends React.Component<Props> {
  render() {
    const { $statistics, $landingPageYear } = this.props;

    if (!$statistics) {
      return <StatisticsSkeleton />;
    }

    if ($statistics.pyjamas === null) {
      return (
        <StyledStatistics>
          <div className="container">
            <div className="row" />
          </div>
        </StyledStatistics>
      );
    }
    const $statisticsWithNames = [
      { $name: intl.get("statistics.pyjamas"), $value: $statistics.pyjamas },
      {
        $name: intl.get("statistics.schoolCount"),
        $value: $statistics.schoolCount,
      },
      {
        $name: intl.get("statistics.swimmingLessons"),
        $value: $statistics.swimmingLessons,
      },
    ];
    const $emptyValue = intl.get("statistics.emptyValue");
    return (
      <StyledStatistics>
        <div className="container">
          <div className="row">
            {$statisticsWithNames &&
              $statisticsWithNames.map((stat, index) => (
                <IndividualStatistic
                  key={index}
                  $index={index}
                  $statistic={stat}
                  $emptyValue={$emptyValue}
                />
              ))}
            <div className="stat-reference">
              <span>
                {intl.get("statistics.reference", {
                  year: ($landingPageYear ?? new Date().getFullYear()) - 1,
                })}
              </span>
            </div>
          </div>
        </div>
      </StyledStatistics>
    );
  }
}

const StyledStatistics = styled.div`
  ${bgGrayOverlay7}
  background-image: url(${StatisticsBg});
  ${bgImgCover}

  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .stat-reference {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: 10px;
      margin-bottom: 5px;
      text-align: right;

      span {
        font-style: italic;
        color: ${white};
      }
    }
  }
`;
