import styled, { css } from "styled-components";
import format from "date-fns/esm/format";
import {
  enCA,
  enAU,
  enGB,
  enIE,
  enIN,
  enNZ,
  enUS,
  enZA,
} from "date-fns/esm/locale";
import { blue, primary } from "../../styles/colors";
import { TimelineEventModel } from "../../models/timelineModel";
import intl from "react-intl-universal";
import { Media, WithinQuery } from "../../styles/mediaQuery";

type TimelineEventProps = {
  $index: number;
  $timelineEvent: TimelineEventModel;
  $language: Locale;
};

const englishLanguages = [enCA, enAU, enGB, enIE, enIN, enNZ, enUS, enZA];

function getDateRangeString(language: Locale, from: Date, to?: Date | null) {
  if (to) {
    const fromMonthString = format(from, "MMMM", {
      locale: language,
    });
    const toMonthString = format(to, "MMMM", {
      locale: language,
    });

    if (fromMonthString !== toMonthString) {
      return !englishLanguages.includes(language)
        ? `${from.getDate()} ${fromMonthString} ${intl.get(
            "landing.to"
          )} ${to.getDate()} ${toMonthString} ${from.getFullYear()}`
        : `${fromMonthString} ${from.getDate()} ${intl.get(
            "landing.to"
          )} ${toMonthString} ${to.getDate()}, ${from.getFullYear()}`;
    }

    return !englishLanguages.includes(language)
      ? `${from.getDate()} ${intl.get(
          "landing.to"
        )} ${to.getDate()} ${fromMonthString} ${from.getFullYear()}`
      : `${fromMonthString} ${from.getDate()} ${intl.get(
          "landing.to"
        )} ${to.getDate()}, ${from.getFullYear()}`;
  }

  return !englishLanguages.includes(language)
    ? format(from, "d MMMM yyyy", { locale: language })
    : format(from, "MMMM d, yyyy", { locale: language });
}

const createMarkup = (text: string) => {
  return { __html: text };
};

export const TimelineEvent = (props: TimelineEventProps) => {
  const { $index, $timelineEvent, $language } = props;
  let dateString = "";
  if ($timelineEvent.dates.length > 0) {
    const from = $timelineEvent.dates[0];
    const to =
      $timelineEvent.dates[1] !== undefined ? $timelineEvent.dates[1] : null;
    dateString = getDateRangeString($language, from, to);
  }
  return (
    <StyledTimelineEvent $isRight={$index % 2 === 0}>
      <div className="marker" />
      <div className="timeline-content">
        <h3>{$timelineEvent.name}</h3>
        {dateString && <span>{dateString}</span>}
        <p
          dangerouslySetInnerHTML={createMarkup($timelineEvent.description)}
        ></p>
      </div>
    </StyledTimelineEvent>
  );
};

type StyledTimelineEventProps = {
  $isRight: boolean;
};

const StyledTimelineEvent = styled.div<StyledTimelineEventProps>`
  ${(props) => props.$isRight && "float:right;"}
  ${(props) => !props.$isRight && "text-align:right;float:left;direction:rtl;"}

  ${WithinQuery(
    Media.XL,
    css`
      -webkit-justify-content: normal !important;
      -webkit-justify-content: normal !important;
      justify-content: normal !important;
    `
  )}

  width: -webkit-calc(50% + 8px);
  width: -moz-calc(50% + 8px);
  width: calc(50% + 8px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  clear: both;

  .marker {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #f5f7fa;
    background: ${primary};
    margin-top: 10px;
    z-index: 2;
  }

  .timeline-content {
    width: 95%;
    padding: 0 15px;
    color: #666;
    direction: ltr;

    h3 {
      margin-top: 5px;
      margin-bottom: 5px;
      font-size: 25px;
      font-weight: 500;
    }

    span {
      font-size: 15px;
      color: #a4a4a4;
    }

    p {
      font-size: 14px;
      line-height: 1.5em;
      word-spacing: 1px;
      color: #888;
      margin-top: 14px;
      margin-bottom: 14px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 30px;
    ${(props) => props.$isRight && "float:none"}
    ${(props) => !props.$isRight && "float:none;direction: ltr"}
  }
`;

type StyledTimelineEvent = {
  $isRight: boolean;
};
