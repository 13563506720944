import React from "react";
import { bgWhite } from "../../styles/theme";
import { SectionHeader } from "../sectionHeader/SectionHeader";
import { TimelineEventModel } from "../../models/timelineModel";
import { enCA, fr } from "date-fns/locale";
import { TimelineSkeleton } from "./Timeline.skeleton";
import { TimelineEvent } from './TimelineEvent';
import styled from 'styled-components';
import { Section } from '../../styles/section';
import intl from "react-intl-universal"

type Props = {
  $language: string;
  $timelineEvents?: TimelineEventModel[];
};

export class Timeline extends React.Component<Props> {
  render() {
    const { $timelineEvents, $language } = this.props;

    if (!$timelineEvents) {
      return <TimelineSkeleton />;
    }

    let currentLanguage = $language;
    if ($language == undefined || $language == null) {
      currentLanguage = "en";
    }

    const currentLocale = currentLanguage.startsWith("en") ? enCA : fr;
    return (
      <StyledTimeline>
        <div className="container">
          <SectionHeader
            className="row"
            $title={intl.get("landing.timelineTitle")}
            $subtitle={intl.get("landing.timelineSubTitle")}
          />
          <div className="timeline">
            {$timelineEvents.map((edm: TimelineEventModel, i: number) => (
              <TimelineEvent key={i} $index={i} $timelineEvent={edm} $language={currentLocale} />
            ))}
          </div>
        </div>
      </StyledTimeline>
    );
  }
}

const StyledTimeline = styled(Section)`
  ${bgWhite}

  .timeline {
    width: 80%;
    padding: 50px 0;
    margin: 50px auto;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -1px;
      width: 2px;
      height: 100%;
      background: #CCD1D9;
      z-index: 1
    }
  }

  @media screen and (max-width: 768px) {
    .timeline:before {
      left: 8px;
      width: 2px;
    }
  }
`;
