type SponsorModelProps = {
  id: string;
  name: string;
  logo: string;
}

export class SponsorModel {
  id: string;
  name: string;
  logo: string;

  constructor(props: SponsorModelProps) {
    this.id = props.id;
    this.name = props.name;
    this.logo = props.logo;
  }
};
