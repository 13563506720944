import React from "react";
import styled from "styled-components";
import { bgWhite, borderGray300 } from "../../styles/theme";
import { alignItemsCenter, justifyContentBetween } from "../../styles/bootstrap";
import { skeletonColor, SkeletonColorShade, skeletonDiv } from "../../styles/skeleton";

export const NewsFeedSkeleton = () => (
  <>
    {/* {IndividualMediaSkeleton({ index: 0})} */}
    <IndividualMediaSkeleton key={0} index={0} />
    <IndividualMediaSkeleton key={1} index={1} />
    <IndividualMediaSkeleton key={2} index={2} />
    <IndividualMediaSkeleton key={3} index={3} />
  </>
);

type Indexed = {
  index: number;
};
const IndividualMediaSkeleton = (props: Indexed) => (
  <div className="col-md-6 col-xl-6">
    <StyledIndividualMediaSkeleton $index={props.index}>
      <div className="pr-md-4">
        <div className="title">
          <div />
        </div>
        <div className="contentLine1" />
        <div className="contentLine2" />
        <div className="publisher">
          <div className="publisher-logo" />
          <div className="publisher-link" />
        </div>
      </div>
      <div className="image" />
    </StyledIndividualMediaSkeleton>
  </div>

);

type StyledIndividualMediaSkeletonProps = {
  $index: number;
};
const StyledIndividualMediaSkeleton = styled.div<StyledIndividualMediaSkeletonProps>`
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.05) !important;
  display: flex;
  margin-top: 1.5rem;
  padding: 1.5rem;
  position: relative;
  border-radius: .25rem;
  border: 1px solid ${skeletonColor(SkeletonColorShade.Light)};
  ${justifyContentBetween}
  ${alignItemsCenter}
  ${bgWhite}
  ${borderGray300};
  
  .title {
    display: block;
    div {
      ${skeletonDiv(SkeletonColorShade.Medium)};
      height: 20px;
      width: 220px;
    }
  }

  .contentLine1, .contentLine2, .contentLine3, .contentLine4 {
    ${skeletonDiv(SkeletonColorShade.Light)}
    display: block;
    height: 10px;
  }

  .contentLine1 {
    margin-top: 1rem;
    width: 240px;
  }

  .contentLine2 {
    margin-top: .6rem;
  }

  .contentLine2 {
    width: 240px;
  }

  .publisher {
    margin-top: 1.5rem;
    .publisher-link {
      ${skeletonDiv(SkeletonColorShade.Light)}
      float: left;
      margin-top: 2px;
      height: 15px;
      vertical-align: middle;
      width: 150px;
    }
    .publisher-logo {
      ${skeletonDiv(SkeletonColorShade.Medium)}
      width: 20px;
      height: 20px;
      float: left;
      margin-right: 5px;
      vertical-align: middle;
    }
  }

  .image {
    ${skeletonDiv(SkeletonColorShade.Medium)}
    width: 100px;
    height: 70px;
    border-radius: .25rem;
    @media (min-width: 768px) {
      display: block !important;
    }
  }
`;
