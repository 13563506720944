import { css, createGlobalStyle } from "styled-components";
import { defaultFont } from "./fonts";
import { normalize } from "polished";
import { gray700 } from "./colors";

const placeholder = css`
  color: ${gray700} !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.6875rem !important;
  letter-spacing: 0.0625rem !important;
  opacity: 1 !important;
  text-overflow: ellipsis !important;
  text-transform: uppercase;
`;

export const removeTextSelection = css`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const scrollbar = css`
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(210, 210, 210, 0.8);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

const globalStyles = css`
  ${normalize()};

  @font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-brands-400.eot);
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-brands-400.eot)
        format("embedded-opentype"),
      url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-brands-400.woff2) format("woff2"),
      url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-brands-400.woff) format("woff"),
      url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-brands-400.ttf) format("truetype"),
      url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-brands-400.svg) format("svg");
  }
  @font-face {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-solid-900.eot);
    src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-solid-900.eot)
        format("embedded-opentype"),
      url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-solid-900.woff2) format("woff2"),
      url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-solid-900.woff) format("woff"),
      url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-solid-900.ttf) format("truetype"),
      url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.7.2/webfonts/fa-solid-900.svg) format("svg");
  }

  html,
  body {
    font-family: ${defaultFont};
    height: 100%;
  }

  #root {
    height: 100%;
  }

  ::-webkit-input-placeholder {
    ${placeholder}
  }
  :-moz-placeholder {
    ${placeholder}
  }
  :-ms-input-placeholder {
    ${placeholder}
  }

  @media (min-width: 1200px) {
    ${scrollbar}
  }

  .grecaptcha-badge {
    bottom: 75px !important;
  }
`;

export const GlobalStyles = createGlobalStyle`${globalStyles}`;
