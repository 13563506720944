import { ArticleModel } from "./articleModel";
import { FormModel } from "./formModel";
import { FrequentlyAskedQuestionModel } from "./frequentlyAskedQuestionModel";
import { OrganizerModel } from "./organizerModel";
import { SchoolModel } from "./schoolModel";
import { SponsorModel } from "./sponsorModel";
import { StatisticModel } from "./statisticModel";
import { TimelineEventModel } from "./timelineModel";

type ProjectModelProps = {
  landingPageYear?: number;
  volunteerApplicationYear?: number;
  volunteerApplicationStartDate?: Date;
  volunteerApplicationEndDate?: Date;
  schools?: SchoolModel[];
  frequentlyAskedQuestions?: FrequentlyAskedQuestionModel[];
  organizers?: OrganizerModel[];
  sponsors?: SponsorModel[];
  statistics?: StatisticModel;
  schedule?: TimelineEventModel[];
  history?: TimelineEventModel[];
  form?: FormModel;
  articles?: ArticleModel[];
};

export class ProjectModel {
  landingPageYear?: number;
  volunteerApplicationYear?: number;
  volunteerApplicationStartDate?: Date;
  volunteerApplicationEndDate?: Date;
  schools?: SchoolModel[];
  frequentlyAskedQuestions?: FrequentlyAskedQuestionModel[];
  organizers?: OrganizerModel[];
  sponsors?: SponsorModel[];
  statistics?: StatisticModel;
  schedule?: TimelineEventModel[];
  history?: TimelineEventModel[];
  form?: FormModel;
  articles?: ArticleModel[];

  constructor(props: ProjectModelProps) {
    this.landingPageYear = props.landingPageYear;
    this.volunteerApplicationYear = props.volunteerApplicationYear;
    this.volunteerApplicationStartDate = props.volunteerApplicationStartDate;
    this.volunteerApplicationEndDate = props.volunteerApplicationEndDate;
    this.schools = props.schools;
    this.frequentlyAskedQuestions = props.frequentlyAskedQuestions;
    this.organizers = props.organizers;
    this.sponsors = props.sponsors;
    this.statistics = props.statistics;
    this.schedule = props.schedule;
    this.history = props.history;
    this.form = props.form;
    this.articles = props.articles;
  }
}

export enum VolunteerApplicationStatus {
  Unknown = 0,
  Open = 1,
  Closed = 2,
  ComingSoon = 3,
}

export const emptyProject = (currentYear: number): ProjectModel => {
  return {
    landingPageYear: currentYear,
    volunteerApplicationYear: currentYear,
    volunteerApplicationStartDate: new Date(),
    volunteerApplicationEndDate: new Date(),
    schools: undefined,
    frequentlyAskedQuestions: undefined,
    organizers: undefined,
    sponsors: undefined,
    statistics: undefined,
    schedule: undefined,
    form: undefined,
  };
};
