import React from "react";
import { SeparatorLine } from "../styles/sep-line";
import { SectionHeader } from "./sectionHeader/SectionHeader";
import styled, { css } from "styled-components";
import { Section } from "../styles/section";
import { blue, gray100, gray700, gray800, primary } from "../styles/colors";
import {
  bgPrimary,
  border,
  TextMedium,
  TextSmall,
  TitleSmall,
} from "../styles/theme";
import { justifyContentCenter, textJustify } from "../styles/bootstrap";
import intl from "react-intl-universal";
import { StyledButton } from "../styles/buttons";
import { fontAlt, fontWeight700, fontWeight600 } from "../styles/fonts";
import { LetterSpacing2, LetterSpacing1 } from "../styles/letter-spacing";
import { ProjectModel } from "../models/projectModel";

type Props = {
  $currentProject: ProjectModel;
};

export class Contribute extends React.Component<Props> {
  createMarkup(text: string) {
    return { __html: text };
  }

  goToUrl = (url: string) => window.location.assign(url);

  render() {
    const { $currentProject } = this.props;
    const { form } = $currentProject;
    var contributionPossibilities = [
      {
        key: "gift",
        name: "Gift",
        buttonEnabled: true,
        buttonVisible: true,
        url: "https://forms.gle/9WpAsNvvWCMqJ67B8",
      },
      {
        key: "pyjamacinema",
        name: "PyjamaCinema",
        buttonEnabled: true,
        buttonVisible: true,
        url: "https://laruchequebec.com/fr/projets/devenez-une-lutine-pour-un-enfant-issu-de-milieux-defavorises#offres",
      },
      {
        key: "swimminglesson",
        name: "SwimmingLesson",
        buttonEnabled: true,
        buttonVisible: true,
        url: "https://laruchequebec.com/fr/projets/devenez-une-lutine-pour-un-enfant-issu-de-milieux-defavorises#offres",
      },
      {
        key: "money",
        name: "Money",
        buttonEnabled: true,
        buttonVisible: true,
        url: "https://laruchequebec.com/fr/projets/devenez-une-lutine-pour-un-enfant-issu-de-milieux-defavorises#offres",
      },
    ];
    return (
      <StyledContribute>
        <div className="container">
          <SectionHeader
            className="row"
            $title={intl.get("landing.contributeTitle")}
            $subtitle={intl.get("landing.contributeSubTitle")}
          />
          <div className="row contribute-content">
            <div className="col-lg-7">
              {contributionPossibilities.map(
                (pos: {
                  key: string;
                  name: string;
                  buttonEnabled: boolean;
                  buttonVisible: boolean;
                  url: string;
                }) => {
                  return (
                    <>
                      <p key={pos.key} className="contribute-content-title">
                        {intl.get("landing.contribute" + pos.name + "Title")}
                      </p>
                      <div
                        dangerouslySetInnerHTML={this.createMarkup(
                          intl.get(
                            "landing.contribute" + pos.name + "Description"
                          )
                        )}
                      />
                      <div className="contribute-button">
                        {pos.buttonVisible && (
                          <StyledButton
                            $isOutline={true}
                            $themeColor="black"
                            $buttonSize="small"
                            onClick={() => this.goToUrl(pos.url)}
                            disabled={pos.buttonEnabled ? "" : "disabled"}
                          >
                            {intl.get(
                              "landing.contribute" + pos.name + "Button"
                            )}{" "}
                            →
                          </StyledButton>
                        )}
                      </div>
                    </>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </StyledContribute>
    );
  }
}

const StyledContribute = styled(Section)`
  background-color: ${gray100};

  div.contribute-title {
    margin-bottom: 3rem;
    ${justifyContentCenter}
    h3 {
      font-family: ${fontAlt};
      ${fontWeight700}
      ${LetterSpacing2}
      margin: 0px;
      ${TitleSmall}
      text-transform: uppercase;
    }

    span.contribute-subtitle {
      display: block;
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-family: ${fontAlt};
      color: ${gray800};
      ${fontWeight600}
      ${LetterSpacing1}
      ${TextSmall}
    }

    ${SeparatorLine} {
      ${bgPrimary}
      display: block;
      margin-top: 1.5rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  div.contribute-content {
    margin-bottom: 5px;
    ${justifyContentCenter}

    p.contribute-content-title {
      font-family: ${fontAlt};
      font-weight: 600;
      letter-spacing: 0.0625rem;
      text-transform: uppercase;
    }

    div {
      margin-bottom: 3rem !important;
      ${textJustify}

      ol {
        list-style: none !important;
        list-style-type: decimal;
        margin: 0;
        margin-left: 3em;
        padding: 0;
        counter-reset: li-counter;

        li {
          text-align: left;
          position: relative;
          margin-bottom: 20px;
          padding-left: 0.5em;
          min-height: 3em;
          border-left: 2px solid ${primary};

          &:before {
            position: absolute;
            top: 0;
            left: -1em;
            width: 0.8em;
            font-size: 3em;
            line-height: 1;
            font-weight: bold;
            text-align: right;
            color: ${gray700};
            content: counter(li-counter);
            counter-increment: li-counter;
          }
        }
      }

      p {
        ${TextMedium}
      }

      .contribute-button {
        button {
          ${border({ $thickness: "thick" })}
          align: center;
          margin-top: 2px;
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }
`;
