type StatisticModelProps = {
  pyjamas: number;
  schoolCount: number;
  swimmingLessons: number;
};

export class StatisticModel {
  pyjamas: number;
  schoolCount: number;
  swimmingLessons: number;

  constructor(props: StatisticModelProps) {
    this.pyjamas = props.pyjamas;
    this.schoolCount = props.schoolCount;
    this.swimmingLessons = props.swimmingLessons;
  }
}
