import React from "react";
import styled from "styled-components";
import {
  TitleLarge,
  TextMedium,
  TitleExtraLarge5,
  TitleExtraLarge3,
} from "../styles/theme";
import { StyledButton } from "../styles/buttons";
import { justifyContentCenter } from "../styles/bootstrap";
import { fontAlt, fontWeight700, fontWeight600 } from "../styles/fonts";
import { LetterSpacing1 } from "../styles/letter-spacing";
import { gray100, gray200 } from "../styles/colors";
import { Media, WithinQuery } from "../styles/mediaQuery";
import parallaxxbg1 from "../assets/parallaxbg-dark-01.jpg";
import parallaxxbg2 from "../assets/parallaxbg-dark-02.jpg";
import { Background, Parallax } from "react-parallax";
import intl from "react-intl-universal";
import { AppSections } from "../App";
import { removeTextSelection } from "../styles/globalStyles";

type Props = {
  children: React.ReactNode;
  $scrollToContent(componentName: AppSections): void;
};

class HeroBase extends React.Component<Props> {
  // <h2>{intl.get("landing.parallaxTitle")}</h2>
  render() {
    const scrollIntoAbout = () =>
      this.props.$scrollToContent(AppSections.About);
    return (
      <StyledHero>
        <Parallax strength={600} style={{ height: "100%" }}>
          <Background className="custom-bg">
            <StyledBackground />
          </Background>
          {this.props.children}
          <div className="container">
            <div className="row d-none d-md-block">
              <div className="col" style={{ height: "50vh" }}></div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-11 text-center">
                <h3>{intl.get("landing.parallaxSubTitle")}</h3>
                <StyledButton
                  $themeColor="primary"
                  $buttonSize="small"
                  $lgSize="large"
                  className="page-scroll"
                  onClick={scrollIntoAbout}
                >
                  {intl.get("landing.parallaxPrimaryButton")}
                </StyledButton>
              </div>
            </div>
          </div>
        </Parallax>
      </StyledHero>
    );
  }
}

export const Hero = HeroBase;

const StyledHero = styled.div`
  & {
    height: 100%;

    & > .react-parallax > .react-parallax-content {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: table;
      text-align: center;

      & > .container {
        position: relative;
        display: table-cell;
        vertical-align: middle;

        & > .row {
          ${justifyContentCenter}
          & div {
            & > h2 {
              text-transform: uppercase;
              margin-top: 1rem;
              font-family: ${fontAlt};
              color: ${gray100};
              ${fontWeight700}
              ${LetterSpacing1}
            ${TitleLarge}
            ${WithinQuery(Media.SM, TitleLarge)}
            ${WithinQuery(Media.MD, TitleExtraLarge5)}
            ${removeTextSelection}
            }

            & > h3 {
              margin-top: 1rem;
              text-transform: uppercase;
              color: ${gray200};
              font-family: ${fontAlt};
              ${fontWeight600}
              ${LetterSpacing1}
            ${TextMedium}
            ${removeTextSelection}
            }

            ${StyledButton} {
              margin-top: 25px;
              margin-left: 0;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

const StyledBackground = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${parallaxxbg1});
  background-size: cover;
  background-position: center;
`;
