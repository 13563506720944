import styled from "styled-components";
import React from "react";
import { SectionHeader } from "./sectionHeader/SectionHeader";
import { SeparatorLine } from "../styles/sep-line";
import { blue, gray700, gray800 } from "../styles/colors";
import { StyledButton } from "../styles/buttons";
import { Section } from "../styles/section";
import {
  justifyContentCenter,
  textCenter,
  textJustify,
} from "../styles/bootstrap";
import {
  bgPrimary,
  bgWhite,
  border,
  TextMedium,
  TextSmall,
  TitleSmall,
} from "../styles/theme";
import { fontAlt, fontWeight600, fontWeight700 } from "../styles/fonts";
import { LetterSpacing1, LetterSpacing2 } from "../styles/letter-spacing";
import intl from "react-intl-universal";
import { AppSections } from "../App";

type Props = {};

export class ContactUs extends React.Component<Props> {
  createMarkup(text: string) {
    return { __html: text };
  }

  openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  render() {
    const contactUsAction = () => {
      const newWindow = window.open(
        "mailto:info@leslutins.org",
        "_blank",
        "noopener,noreferrer"
      );
      if (newWindow) {
        newWindow.opener = null;
      }
    };
    return (
      <>
        <StyledContactUs>
          <div className="container">
            <SectionHeader
              className="row"
              $title={intl.get("landing.contactUsTitle")}
              $subtitle={intl.get("landing.contactUsSubtitle")}
            />
            <div className="row contactus-content">
              <div className="col-lg-12 col-xl-10 text-center">
                {/* <h3>{intl.get("landing.contactUsContent")}</h3>
                <SeparatorLine $length="long" $thickness="medium-thick" />
                <span className="bg-blue d-block mt-4 sep-line-medium-thick-long"></span> */}
                <StyledButton
                  className="scroll-to-register"
                  $themeColor="primary"
                  $lgSize="large"
                  $buttonSize="medium"
                  onClick={contactUsAction}
                >
                  {intl.get("landing.contactUsPrimaryButton")}
                </StyledButton>
              </div>
            </div>
          </div>
        </StyledContactUs>
      </>
    );
  }
}

const StyledContactUs = styled(Section)`
  ${bgWhite}

  .contactus-content {
    ${justifyContentCenter}
    h3 {
      font-family: ${fontAlt} !important;
      ${fontWeight700};
      ${LetterSpacing2};
      margin: 0px;
      text-transform: uppercase !important;
      font-size: 1.375rem !important;
      line-height: 1.875rem !important;
    }
  }

  ${SeparatorLine} {
    ${bgPrimary}
    margin-top: 1.5rem !important;
  }
`;
