import React from "react";
import styled from "styled-components";
import { justifyContentCenter } from "../../styles/bootstrap";
import { white } from "../../styles/colors";
import { Section } from "../../styles/section";
import { SectionHeaderSkeleton } from "../sectionHeader/SectionHeader.skeleton";
import { SkeletonColorShade, skeletonDiv } from "../../styles/skeleton";

export const IndividualSponsorSkeleton = () => (
  <StyledIndividualSponsorSkeleton className="col-sm-6 col-md-4">
    <div />
  </StyledIndividualSponsorSkeleton>
);

const StyledIndividualSponsorSkeleton = styled.div`
  text-align: center;

  div {
    ${skeletonDiv(SkeletonColorShade.Light)}
    width: 200px;
    height: 87.33px;
    margin-top: 15px;
    margin-bottom: 15px;
    vertical-align: middle;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.4rem;
  }
`;

export const SponsorsSkeleton = () => {
  return (
    <StyledSponsorsSkeleton>
      <div className="container">
        <SectionHeaderSkeleton className="row" shade={SkeletonColorShade.Light} inverseTextWidth={false} />
        <div className="row sponsors-content">
          <div className="col-lg-10">
            <div className="row">
              <IndividualSponsorSkeleton />
              <IndividualSponsorSkeleton />
              <IndividualSponsorSkeleton />
              <IndividualSponsorSkeleton />
              <IndividualSponsorSkeleton />
              <IndividualSponsorSkeleton />
            </div>
          </div>
        </div>
      </div>
    </StyledSponsorsSkeleton>
  );
};

const StyledSponsorsSkeleton = styled(Section)`
  background-color: ${white};

  .sponsors-content {
    ${justifyContentCenter}
  }
`;
