import { set as setCookie, get as getCookie } from 'es-cookie';
import { defaultLocale, supportedLocales } from "../i18n/_locales";


export function isLocaleValid(option: string) {
    return supportedLocales.indexOf(option) !== -1;
}

export function setLocaleSettings(option: string): string {
    if (supportedLocales.indexOf(option) === -1) {
        console.error(`Invalid ${option} for supportedLocales (${supportedLocales}). Fallback to ${defaultLocale}`)
        option = defaultLocale
    }
    setCookie("lang", option);
    return option;
}
/*getLocaleSettings: (): string => {
    return getCurrentLocale()
},*/

export function getCurrentLocale(): string {
    var currentLocale = getCookie("lang");
    if (currentLocale !== undefined && supportedLocales.indexOf(currentLocale) === -1) {
        console.log(`Fetching ${currentLocale}`)
        return currentLocale;
    }
    console.error(`Invalid ${currentLocale} for cookie lang. Fallback to ${defaultLocale}`)
    setCookie("lang", defaultLocale);
    return defaultLocale;
}
