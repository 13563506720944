type SchoolModelProps = {
  name: string;
  location: string;
  imageSource: string;
}

export class SchoolModel {
  name: string;
  location: string;
  imageSource: string;

  constructor(props: SchoolModelProps) {
    this.name = props.name;
    this.location = props.location;
    this.imageSource = props.imageSource;
  }
};
