import { css, FlattenSimpleInterpolation } from "styled-components";

/*
 * Media Queries
 */
export enum Media {
  ZERO = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200
}

export const WithinQuery = (pixels: Media, styleWithinQuery: FlattenSimpleInterpolation) => css`
  @media (min-width: ${pixels}px) {
    ${styleWithinQuery}
  }
`;
