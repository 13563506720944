type OrganizerModelProps = {
  fullName: string;
  title: string;
  imageSource: string;
}

export class OrganizerModel {
  fullName: string;
  title: string;
  imageSource: string;

  constructor(props: OrganizerModelProps) {
    this.fullName = props.fullName;
    this.title = props.title;
    this.imageSource = props.imageSource;
  }
};
