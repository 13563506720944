type TimelineEventModelProps = {
  name: string;
  description: string;
  dates: Date[];
};

export class TimelineEventModel {
  name: string;
  description: string;
  dates: Date[];

  constructor(props: TimelineEventModelProps) {
    this.name = props.name;
    this.description = props.description;
    this.dates = props.dates;
  }
};

export type ScheduleEventModel = { [key: string]: TimelineEventModel };