import styled, { css } from "styled-components";

type SeparatorLineProps = {
  $thickness?:
  | "thin"
  | "thick"
  | "medium-thick"
  | "extra-thick";
  $length?:
  | "full"
  | "long";
}

export const SeparatorLine = styled.span<SeparatorLineProps>`
  display: block;
  height: 2px;
  width: 30px;
  ${prop => prop.$thickness && prop.$thickness === "thin" && css`
    height: 1px;
  `}
  ${prop => prop.$thickness && prop.$thickness === "thick" && css`
    height: 4px;
  `}
  ${prop => prop.$thickness && prop.$thickness === "medium-thick" && css`
    height: 6px;
  `}
  ${prop => prop.$thickness && prop.$thickness === "extra-thick" && css`
    height: 8px;
  `}
  ${prop => prop.$length && prop.$length === "long" && css`
    width: 80px;
  `}
  ${prop => prop.$length && prop.$length === "full" && css`
    width: 100%;
  `}
`