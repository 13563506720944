import React from "react";
import styled from "styled-components";
import {
  bgPrimary,
  TitleSmall,
  TextSmall,
  TextExtraSmall,
  bgWhite,
} from "../styles/theme";
import { justifyContentCenter } from "../styles/bootstrap";
import { SeparatorLine } from "../styles/sep-line";
import { fontAlt, fontWeight700, fontWeight600 } from "../styles/fonts";
import { LetterSpacing2, LetterSpacing1 } from "../styles/letter-spacing";
import { gray800 } from "../styles/colors";
import { Section } from "../styles/section";
import { SectionHeader } from "./sectionHeader/SectionHeader";
import intl from "react-intl-universal";
import { NewsFeed } from "./news/NewsFeed";
import { ArticleModel } from "../models/articleModel";

type Props = {
  $articles?: ArticleModel[];
};

export class News extends React.Component<Props> {
  render() {
    return (
      <StyledNews>
        <div className="container">
          <SectionHeader
            className="row contact-us-header"
            $title={intl.get("landing.newsTitle")}
            $subtitle={intl.get("landing.newsSubTitle")}
          />
          <div className="row">
            <NewsFeed $articles={this.props.$articles} />
          </div>
        </div>
      </StyledNews>
    );
  }
}

const StyledNews = styled(Section)`
  ${bgWhite}
  padding-bottom: 3rem;

  .contact-us-header {
    margin-bottom: 3rem;
    ${justifyContentCenter}
  }

  .contact-us-body {
    .form-title {
      margin: 0;
      text-transform: uppercase;
      font-family: ${fontAlt};
      ${TitleSmall}
      ${fontWeight700}
      ${LetterSpacing2}
    }

    .form-subtitle {
      display: block;
      margin-top: 0.5rem;
      text-transform: uppercase;
      color: ${gray800};
      font-family: ${fontAlt};
      ${TextSmall}
      ${fontWeight600}
      ${LetterSpacing1}
      a {
        ${TextExtraSmall}
      }
    }

    ${SeparatorLine} {
      ${bgPrimary}
      display: block;
      margin-top: 1.5rem;
    }
  }
`;
