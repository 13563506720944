import intl from "react-intl-universal";
import locales, {
  defaultLocale,
  getSavedLocale,
  supportedLocales,
} from "./i18n/_locales";
import React, {
  CSSProperties,
  FC,
  ForwardRefRenderFunction,
  LegacyRef,
  Ref,
} from "react";
import { setLocaleSettings } from "./settings/settings";
import { Hero } from "./components/Hero";
import { GlobalStyles } from "./styles/globalStyles";
import { About } from "./components/About";
import { Header } from "./components/Header";
import { GiftDistribution } from "./components/GiftDistribution";
import { Schools } from "./components/schools/Schools";
import { SchoolModel } from "./models/schoolModel";
import { OrganizerModel } from "./models/organizerModel";
import { emptyProject, ProjectModel } from "./models/projectModel";
import { projects } from "./data/projects/_projects";
import { Organizers } from "./components/organizers/Organizers";
import { VolunteerApplication } from "./components/VolunteerApplication";
import { Timeline } from "./components/timeline/Timeline";
import { TimelineEventModel } from "./models/timelineModel";
import { MediaGallery } from "./components/MediaGallery";
import { Sponsors } from "./components/sponsors/Sponsors";
import { SponsorModel } from "./models/sponsorModel";
import { FrequentlyAskedQuestions } from "./components/frequentlyAskedQuestions/FrequentlyAskedQuestions";
import { FrequentlyAskedQuestionModel } from "./models/frequentlyAskedQuestionModel";
import { Statistics } from "./components/statistics/Statistics";
import { StatisticModel } from "./models/statisticModel";
import { News } from "./components/Articles";
import { ArticleModel } from "./models/articleModel";
import { MessengerChat } from "react-messenger-chat-plugin";
import { primary } from "./styles/colors";
import { Footer } from "./components/Footer";
import styled, { StyleSheetManager } from "styled-components";
import { FormModel } from "./models/formModel";
import { Contribute } from "./components/Contribute";
import { ContactUs } from "./components/ContactUs";

// ---------
// Constants
// ---------
const facebookPageId: string = "281201009198390";

export enum AppSections {
  ToTop = 0,
  About = 1,
  Schools = 2,
  VolunteerApplication = 3,
  Timeline = 4,
  MediaGallerry = 5,
  FrequentlyAskedQuestions = 6,
  ContactUs = 7,
  Contribute = 8,
}

type Props = {
  $current: number;
};

type StateProps = {
  currentLocale: string;
  initDone: boolean;
  project: ProjectModel;
  isShrinked: boolean;
  isMessengerLoaded: boolean;
};

type AnchorProps = {
  children: React.ReactNode;
  style?: CSSProperties | undefined;
};

const Anchor = React.forwardRef<HTMLDivElement, AnchorProps>(
  (props: AnchorProps, ref: React.ForwardedRef<HTMLDivElement>) => (
    <div ref={ref} {...props}>
      {props.children}
    </div>
  )
);

function shouldForwardProp(propName: string, target: any) {
  return true;
}

class App extends React.Component<Props, StateProps> {
  totop?: HTMLElement | null;
  about?: HTMLElement | null;
  schools?: HTMLElement | null;
  volunteering?: HTMLElement | null;
  timeline?: HTMLElement | null;
  media?: HTMLElement | null;
  faq?: HTMLElement | null;
  contactUs?: HTMLElement | null;
  contribute?: HTMLElement | null;

  constructor(props: Props) {
    super(props);
    window.onscroll = () => this.scrollFunction();
    this.scrollToContent = this.scrollToContent.bind(this);
    const lang: string = getSavedLocale();
    this.state = {
      currentLocale: lang,
      isShrinked: this.shouldShrink(),
      initDone: false,
      project: emptyProject(props.$current),
      isMessengerLoaded: false,
    };
  }

  scrollFunction() {
    const shrinkTest = this.shouldShrink();
    if (this.state.isShrinked !== shrinkTest) {
      this.setState({ isShrinked: shrinkTest });
    }
  }

  shouldShrink() {
    return (
      document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
    );
  }

  componentDidMount() {
    this.loadLocales();
    this.setState({ initDone: true });
    this.loadProject(false);
  }

  loadLocales() {
    const currentLocale = this.state.currentLocale;
    intl.init({
      currentLocale,
      locales: { [currentLocale]: locales[currentLocale] },
    });
  }

  createDate(date: string) {
    const match: RegExpExecArray | null = /^(\d{4})-(\d{2})-(\d{2})/.exec(date);
    if (match) {
      return new Date(
        parseInt(match[1]),
        parseInt(match[2]) - 1,
        parseInt(match[3])
      );
    }
    console.error(`Date is invalid: ${date}`);
    return new Date();
  }

  loadProject(isChange: boolean) {
    if (isChange) {
      this.setState({ project: emptyProject(this.props.$current) });
    }

    const currentProject = projects[this.props.$current];
    let project: ProjectModel = emptyProject(this.props.$current);
    // project.volunteerApplicationStartDate = this.createDate(
    //   currentProject.volunteerApplicationStartDate
    // );
    // project.volunteerApplicationEndDate = this.createDate(
    //   currentProject.volunteerApplicationEndDate
    // );

    const { elf, eventVolunteer, funding } = currentProject.forms;
    project.form = new FormModel({ elf, eventVolunteer, funding });

    project.schools = currentProject.schools.map((s: string) => {
      return new SchoolModel({
        name: intl.get(`schools.${s}.name`),
        location: intl.get(`schools.${s}.address`),
        imageSource: intl.get(`schools.${s}.picture`),
      });
    });

    project.organizers = currentProject.organizers.map((s: string) => {
      return new OrganizerModel({
        fullName: intl.get(`organizers.${s}.displayName`),
        title: intl.get(`organizers.${s}.title`),
        imageSource: intl.get(`organizers.${s}.picture`),
      });
    });

    project.history = Object.keys(currentProject.history).map((key: string) => {
      const currentTimeline = currentProject.history[key];
      return new TimelineEventModel({
        dates: currentTimeline.date.map(this.createDate),
        name: intl.get(`history.${key}.name`),
        description: intl.get(`history.${key}.description`),
      });
    });

    project.schedule = Object.keys(currentProject.schedule).map(
      (key: string) => {
        const currentTimeline = currentProject.schedule[key];
        return new TimelineEventModel({
          dates: currentTimeline.date.map(this.createDate),
          name: intl.get(`schedule.${key}.name`),
          description: intl.get(`schedule.${key}.description`),
        });
      }
    );

    project.sponsors = currentProject.sponsors.map((sponsorKey: string) => {
      return new SponsorModel({
        id: sponsorKey,
        name: intl.get(`sponsors.${sponsorKey}.name`),
        logo: intl.get(`sponsors.${sponsorKey}.logo`),
      });
    });

    project.frequentlyAskedQuestions = [0, 1, 2, 3].map((index: number) => {
      return new FrequentlyAskedQuestionModel({
        question: intl.get(`faq.${index}.question`),
        answer: intl.get(`faq.${index}.answer`),
      });
    });

    const { pyjamas, schoolCount, swimmingLessons } = currentProject.statistics;
    project.statistics = new StatisticModel({
      pyjamas,
      schoolCount,
      swimmingLessons,
    });

    project.articles = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(
      (index: number) =>
        new ArticleModel({
          title: intl.get(`articles.${index}.title`),
          description: intl.get(`articles.${index}.description`),
          link: intl.get(`articles.${index}.link`),
          cover: intl.get(`articles.${index}.cover`),
          publisherName: intl.get(`articles.${index}.publisherName`),
          publisherLink: intl.get(`articles.${index}.publisherLink`),
          publisherLogo: intl.get(`articles.${index}.publisherLogo`),
        })
    );

    this.setState({ project });
  }

  onLocaleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const currentLocale = supportedLocales.some((l) => l == e.target.value)
      ? e.target.value
      : defaultLocale;
    const validLocale = setLocaleSettings(currentLocale);
    intl.init({
      currentLocale: validLocale,
      locales: { [validLocale]: locales[validLocale] },
    });
    this.loadProject(true);
    this.setState({ currentLocale: validLocale });
  };

  scrollToContent(componentName: AppSections): void {
    let currentElement: HTMLElement | null | undefined;
    switch (componentName) {
      case AppSections.ToTop:
        currentElement = this.totop;
        break;
      case AppSections.About:
        currentElement = this.about;
        break;
      case AppSections.Schools:
        currentElement = this.schools;
        break;
      case AppSections.VolunteerApplication:
        currentElement = this.volunteering;
        break;
      case AppSections.Timeline:
        currentElement = this.timeline;
        break;
      case AppSections.MediaGallerry:
        currentElement = this.media;
        break;
      case AppSections.FrequentlyAskedQuestions:
        currentElement = this.faq;
        break;
      case AppSections.ContactUs:
        currentElement = this.contactUs;
        break;
      case AppSections.Contribute:
        currentElement = this.contribute;
        break;
    }
    if (currentElement) {
      currentElement.scrollIntoView({ behavior: "smooth" });
    }
  }

  render() {
    const { currentLocale, project } = this.state;
    const {
      schools,
      organizers,
      schedule,
      history,
      sponsors,
      frequentlyAskedQuestions,
      statistics,
      articles,
    } = project;

    return (
      <>
        <GlobalStyles />
        {this.state.initDone && (
          <>
            <Anchor
              ref={(r) => {
                this.totop = r;
              }}
              style={{ width: "100%", height: "100%" }}
            >
              <Hero $scrollToContent={this.scrollToContent}>
                <Header
                  $isShrinked={this.state.isShrinked}
                  scrollToContent={this.scrollToContent}
                >
                  <select value={currentLocale} onChange={this.onLocaleChange}>
                    <option value="en-US">{intl.get("common.en")}</option>
                    <option value="fr-CA">{intl.get("common.fr")}</option>
                  </select>
                </Header>
              </Hero>
            </Anchor>
            <Anchor
              ref={(r) => {
                this.about = r;
              }}
            >
              <About $scrollToContent={this.scrollToContent} />
            </Anchor>
            {/* <GiftDistribution $scrollToContent={this.scrollToContent} /> */}
            {/* <Anchor
              ref={(r) => {
                this.schools = r;
              }}
            >
              <Schools $schools={schools} />
            </Anchor> */}
            {/* <Organizers $organizers={organizers} /> */}
            {/* <Anchor
              ref={(r) => {
                this.volunteering = r;
              }}
            >
              <VolunteerApplication
                $currentProject={project}
                $language={currentLocale}
              />
            </Anchor> */}
            <Anchor
              ref={(r) => {
                this.contribute = r;
              }}
            >
              <Contribute $currentProject={project} />
            </Anchor>
            <Statistics
              $landingPageYear={project.landingPageYear}
              $statistics={statistics}
            />
            {/* <Anchor
              ref={(r) => {
                this.timeline = r;
              }}
            >
              <Timeline $language={currentLocale} $timelineEvents={schedule} />
            </Anchor> */}
            <Anchor
              ref={(r) => {
                this.timeline = r;
              }}
            >
              <Timeline $language={currentLocale} $timelineEvents={history} />
            </Anchor>
            <Anchor
              ref={(r) => {
                this.media = r;
              }}
            >
              <MediaGallery />
            </Anchor>
            <Sponsors $sponsors={sponsors} />
            {/* <Anchor
              ref={(r) => {
                this.faq = r;
              }}
            >
              <FrequentlyAskedQuestions
                $frequentlyAskedQuestions={frequentlyAskedQuestions}
                $isMessengerLoaded={this.state.isMessengerLoaded}
              />
            </Anchor> */}
            <News $articles={articles} />
            <Anchor
              ref={(r) => {
                this.contactUs = r;
              }}
            >
              <ContactUs />
            </Anchor>
            <MessengerChat
              pageId={facebookPageId}
              language={currentLocale.startsWith("fr") ? "fr_FR" : "en_US"}
              themeColor={primary}
              loggedInGreeting={intl.get("common.messengerGreetingLoggedIn")}
              loggedOutGreeting={intl.get("common.messengerGreetingLoggedOut")}
              greetingDialogDisplay={"icon"}
              onMessengerLoad={() => this.setState({ isMessengerLoaded: true })}
            />
            <Footer />
            {/* <ScrollToTop scrollToContent={this.scrollToContent} isShrinked={this.state.isShrinked} /> */}
          </>
        )}
      </>
    );
  }
}

export default App;
