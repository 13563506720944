import en from "./en-US.json"
import fr from "./fr-CA.json"
import intl from "react-intl-universal"
import { setLocaleSettings } from "../settings/settings";

export const defaultLocale = "fr-CA";
export const supportedLanguages = ["fr", "en"];
export const supportedLocales = ["fr-CA", "en-US"];

const locales: { [k: string]: any } = {
  "en-US": en,
  "fr-CA": fr,
}

const flattenJSON = (obj: any, res: any = {}, extraKey = '') => {
  for (let key in obj) {
    if (typeof obj[key] !== 'object') {
      res[extraKey + key] = obj[key];
    } else {
      flattenJSON(obj[key], res, `${extraKey}${key}.`);
    };
  };
  return res;
};

const locales2: { [k: string]: any } = {
  "en-US": flattenJSON(en),
  "fr-CA": flattenJSON(fr),
}

export const LOCALES_LIST = [
  {
    label: "Fr",
    value: "fr-CA",
  },
  {
    label: "En",
    value: "en-US",
  }
]

export function getSavedLocale() {
  var detLoc = intl.determineLocale({
    urlLocaleKey: 'lang', // Example: https://fe-tool.com/react-intl-universal?lang=en-US
    cookieLocaleKey: 'lang', // Example: "lang=en-US" in cookie
  });
  if (supportedLocales.includes(detLoc)) {
    return detLoc;
  }
  if (detLoc.length > 2
    && detLoc.indexOf("-") !== -1
    && supportedLanguages.includes(detLoc.substring(0, detLoc.indexOf("-")))) {
    return setLocaleSettings(`${detLoc.substring(0, detLoc.indexOf("-"))}${detLoc.startsWith("en") ? "US" : "CA"}`);
  }
  return setLocaleSettings("fr-CA");
}

export default locales