import styled from "styled-components";
import React from "react";
import { SectionHeader } from "./sectionHeader/SectionHeader";
import { SeparatorLine } from "../styles/sep-line";
import { blue, gray700, gray800 } from "../styles/colors";
import { StyledButton } from "../styles/buttons";
import { Section } from "../styles/section";
import {
  justifyContentCenter,
  textCenter,
  textJustify,
} from "../styles/bootstrap";
import {
  bgPrimary,
  bgWhite,
  border,
  TextMedium,
  TextSmall,
  TitleSmall,
} from "../styles/theme";
import { fontAlt, fontWeight600, fontWeight700 } from "../styles/fonts";
import { LetterSpacing1, LetterSpacing2 } from "../styles/letter-spacing";
import intl from "react-intl-universal";
import { AppSections } from "../App";
import GoalPicture1 from "../assets/about-goal-01.jpg";
import GoalPicture2 from "../assets/about-goal-02.jpg";
import GoalPicture3 from "../assets/about-goal-03.jpg";

type Props = {
  $scrollToContent(componentName: AppSections): void;
};

export class About extends React.Component<Props> {
  createMarkup(text: string) {
    return { __html: text };
  }

  render() {
    const scrollIntoContribute = () =>
      this.props.$scrollToContent(AppSections.Contribute);
    return (
      <>
        <StyledAbout>
          <div className="container">
            <SectionHeader
              className="row"
              $title={intl.get("landing.aboutTitle")}
              $subtitle={intl.get("landing.aboutSubTitle")}
            />
            <div className="about-pictures row">
              <div className="about-picture col-md-4 col-lg-4">
                <img className="img-fluid" src={GoalPicture1} />
                <p>Édition 2019</p>
              </div>
              <div className="about-picture col-md-4 col-lg-4">
                <img className="img-fluid" src={GoalPicture2} />
                <p>Édition 2020</p>
              </div>
              <div className="about-picture col-md-4 col-lg-4">
                <img className="img-fluid" src={GoalPicture3} />
                <p>Édition 2023</p>
              </div>
            </div>
            <div className="row about-content">
              <div className="col-lg-7">
                <p className="about-content-title">
                  {intl.get("landing.aboutOriginTitle")}
                </p>
                <div
                  dangerouslySetInnerHTML={this.createMarkup(
                    intl.get("landing.aboutOriginContent")
                  )}
                />
                <p className="about-content-title">
                  {intl.get("landing.aboutSuccessStoryTitle")}
                </p>
                <div
                  dangerouslySetInnerHTML={this.createMarkup(
                    intl.get("landing.aboutSuccessStoryContent")
                  )}
                />
                <p className="about-content-title">
                  {intl.get("landing.aboutBecomingOBNLTitle")}
                </p>
                <div
                  dangerouslySetInnerHTML={this.createMarkup(
                    intl.get("landing.aboutBecomingOBNLContent")
                  )}
                />
                <div className="about-button">
                  <StyledButton
                    className="scroll-to-register"
                    $isOutline={true}
                    $themeColor="black"
                    $buttonSize="small"
                    onClick={scrollIntoContribute}
                  >
                    {intl.get("landing.aboutPrimaryButton")}
                  </StyledButton>
                </div>
              </div>
            </div>
          </div>
        </StyledAbout>
      </>
    );
  }
}

const StyledAbout = styled(Section)`
  ${bgWhite}

  div.about-pictures {
    margin-bottom: 1rem !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;

    div.about-picture {
      p {
        text-align: center;
        font-style: italic;
        color: #a4a4a4;
      }
    }
  }

  div.about-title {
    margin-bottom: 3rem;
    ${justifyContentCenter}
    h3 {
      font-family: ${fontAlt};
      ${fontWeight700}
      ${LetterSpacing2}
      margin: 0px;
      ${TitleSmall}
      text-transform: uppercase;
    }

    span.about-subtitle {
      display: block;
      margin-top: 0.5rem;
      text-transform: uppercase;
      font-family: ${fontAlt};
      color: ${gray800};
      ${fontWeight600}
      ${LetterSpacing1}
      ${TextSmall}
    }

    ${SeparatorLine} {
      ${bgPrimary}
      display: block;
      margin-top: 1.5rem;
      margin-right: auto;
      margin-left: auto;
    }
  }

  div.about-content {
    margin-bottom: 5px;
    ${justifyContentCenter}

    p.about-content-title {
      font-family: ${fontAlt};
      font-weight: 600;
      letter-spacing: 0.0625rem;
      text-transform: uppercase;
    }

    div {
      margin-bottom: 3rem !important;
      ${textJustify}

      ol {
        list-style: none !important;
        list-style-type: decimal;
        margin: 0;
        margin-left: 3em;
        padding: 0;
        counter-reset: li-counter;

        li {
          text-align: left;
          position: relative;
          margin-bottom: 20px;
          padding-left: 0.5em;
          min-height: 3em;
          border-left: 2px solid ${blue};

          &:before {
            position: absolute;
            top: 0;
            left: -1em;
            width: 0.8em;
            font-size: 3em;
            line-height: 1;
            font-weight: bold;
            text-align: right;
            color: ${gray700};
            content: counter(li-counter);
            counter-increment: li-counter;
          }
        }
      }

      p {
        ${TextMedium}
      }

      .about-button {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          ${border({ $thickness: "thick" })}
          align: center;
          margin-top: 2px;
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }
`;
