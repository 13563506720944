const CURRENT_YEAR = 2024;

import { createRoot } from "react-dom/client";
import App from "./App";

const container = document.getElementById("root");

const root = createRoot(container);

root.render(<App $current={CURRENT_YEAR} />);
