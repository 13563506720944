import React from "react";
import { SeparatorLine } from "../styles/sep-line";
import { SectionHeader } from "./sectionHeader/SectionHeader";
import styled, { css } from "styled-components";
import { Section } from "../styles/section";
import { gray100 } from "../styles/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { bgPrimary } from "../styles/theme";
import { justifyContentCenter } from "../styles/bootstrap";
import { WithinQuery, Media } from "../styles/mediaQuery";
import intl from "react-intl-universal";

type Props = {};

export class MediaGallery extends React.Component<Props> {
  render() {
    return (
      <StyledMedia>
        <div className="container">
          <SectionHeader
            className="row"
            $title={intl.get("landing.mediaTitle")}
            $subtitle={intl.get("landing.mediaSubTitle")}
          />
          <div className="row gallery-youtube-content">
            <div className="col-lg-10 mx-auto text-center">
              <div className="mb-3">
                <iframe
                  className="featured-video"
                  allowFullScreen={true}
                  src="https://www.youtube.com/embed/5cE25Yyf0jU?ecver=1&amp;iv_load_policy=1&amp;rel=0&amp;yt:stretch=16:9&amp;autohide=1&amp;color=red&amp;width=920&amp;width=920"
                  width={920}
                  height={520}
                />
              </div>
            </div>
          </div>
          <div className="row gallery-footer gallery-youtube-footer">
            <div className="col-lg-12">
              <span>
                {intl.get("landing.seeMoreVideos")},{" "}
                <a href="https://www.youtube.com/@LesLutins-d5m">
                  {intl.get("landing.followUsYouTube")}{" "}
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                !
              </span>
            </div>
          </div>
          <div className="row seperator">
            <div className="col-lg-6">
              <SeparatorLine $thickness="thin" $length="full" />
            </div>
          </div>
          <div>
            <div className="col-12">
              <script src="https://snapwidget.com/js/snapwidget.js" />
              <iframe
                src="https://snapwidget.com/embed/885055"
                className="snapwidget-widget"
                scrolling="no"
              />
              <div className="snapwidget-widget-footer">
                <a href="https://snapwidget.com">
                  SnapWidget · Instagram Widget
                </a>
              </div>
            </div>
          </div>
          <div className="row gallery-footer">
            <div className="col-lg-12">
              <span>
                {intl.get("landing.seeMorePhotos")},{" "}
                <a href="https://www.instagram.com/leslutins_obnl/">
                  {intl.get("landing.followUsInstagram")}{" "}
                  <FontAwesomeIcon icon={faInstagram} />
                </a>{" "}
                {intl.get("landing.and")}{" "}
                <a href="https://www.tiktok.com/@lutinsvm/">
                  TikTok <FontAwesomeIcon icon={faTiktok} />
                </a>
                !
              </span>
            </div>
          </div>
        </div>
      </StyledMedia>
    );
  }
}

const StyledMedia = styled(Section)`
  background-color: ${gray100};

  .gallery-wrapper {
    clear: both;
    width: 100%;
  }

  .seperator {
    ${justifyContentCenter}
    margin-bottom: 1.5rem;

    ${SeparatorLine} {
      ${bgPrimary}
    }
  }

  iframe.featured-video {
    width: 100%;
    border: 0;

    ${WithinQuery(
      Media.SM,
      css`
        height: 290px;
      `
    )}
    ${WithinQuery(
      Media.MD,
      css`
        height: 390px;
      `
    )}
    ${WithinQuery(
      Media.LG,
      css`
        height: 435px;
      `
    )}
    ${WithinQuery(
      Media.XL,
      css`
        height: 520px;
      `
    )}
  }

  iframe.snapwidget-widget {
    border: none;
    overflow: hidden;
    width: 100%;
    display: inline-block;

    ${WithinQuery(
      Media.SM,
      css`
        height: 175px;
      `
    )}
    ${WithinQuery(
      Media.MD,
      css`
        height: 260px;
      `
    )}
    ${WithinQuery(
      Media.LG,
      css`
        height: 355px;
      `
    )}
    ${WithinQuery(
      Media.XL,
      css`
        height: 431px;
      `
    )}
  }

  .snapwidget-widget-footer {
    font: 10px/14px "Roboto", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: 400;
    width: 100%;
    text-align: right;

    a {
      color: #777;
      text-decoration: none;
    }
  }

  .gallery-youtube-footer {
    margin-bottom: 1.5rem;
  }

  .gallery-footer {
    div {
      text-align: center;
      a {
      }
    }
  }
`;
