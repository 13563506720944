import styled, { css } from "styled-components";
import {
  white,
  black,
  gray900,
  blue,
  green,
  orange,
  pink,
  red,
  yellow,
  gray3,
  gray2,
  gray1,
  primary,
  primaryBoxShadow,
  primaryHoverBg,
  primaryHoverBorder,
  primaryActiveBg,
  primaryActiveBorder,
  secondary,
  secondaryHoverBg,
  secondaryHoverBorder,
  secondaryActiveBg,
  secondaryActiveBorder,
} from "./colors";
import Button from "react-bootstrap/Button";

type StyledButtonProps = {
  $themeColor?:
    | "primary"
    | "secondary"
    | "black"
    | "white"
    | "blue"
    | "green"
    | "orange"
    | "pink"
    | "red"
    | "yellow";
  $isOutline?: boolean;
  $buttonSize?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  $smSize?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  $mdSize?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  $lgSize?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
  $xlSize?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
};

const Outline = (color: string) => css<StyledButtonProps>`
  color: ${color};
  background-color: transparent;
  background-image: none;
  &:disabled,
  &.disabled {
    color: ${color};
    background-color: transparent;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background-color: ${color};
    border-color: ${color};
  }
`;

const PrimaryButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${white};
  background-color: ${primary};
  border-color: ${primary};
  &:hover {
    color: ${white};
    background-color: ${primaryHoverBg};
    border-color: ${primaryHoverBorder};
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem ${primaryBoxShadow};
  }
  &.disabled,
  &:disabled {
    color: ${white};
    background-color: ${primary};
    border-color: ${primary};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${white};
    background-color: ${primaryActiveBg};
    border-color: ${primaryActiveBorder};
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem ${primaryBoxShadow};
  }
  ${isOutline && Outline(primary)}
`;

const SecondaryButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${white};
  background-color: ${secondary};
  border-color: ${secondary};
  &:hover {
    color: ${white};
    background-color: ${secondaryHoverBg};
    border-color: ${secondaryHoverBorder};
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 100, 191, 0.5);
  }
  &.disabled,
  &:disabled {
    color: ${white};
    background-color: ${secondary};
    border-color: ${secondary};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${white};
    background-color: ${secondaryActiveBg};
    border-color: ${secondaryActiveBorder};
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 100, 191, 0.5);
  }
  ${isOutline && Outline(secondary)}
`;

const BlackButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${white};
  background-color: ${black};
  border-color: ${black};
  &:hover {
    color: ${white};
    background-color: ${black};
    border-color: ${black};
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
  }
  &:disabled,
  &.disabled {
    color: ${white};
    background-color: ${black};
    border-color: ${black};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${white};
    background-color: ${black};
    border-color: ${black};
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
  }
  ${isOutline && Outline(black)}
`;

const WhiteButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${gray900};
  background-color: ${white};
  border-color: ${white};
  &:hover {
    color: ${gray900};
    background-color: ${gray1};
    border-color: ${gray2};
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  &.disabled,
  &:disabled {
    color: ${gray900};
    background-color: ${white};
    border-color: ${white};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${gray900};
    background-color: ${gray2};
    border-color: ${gray3};
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
  }
  ${isOutline &&
  css`
    ${Outline(white)}
    &:hover, &:active {
      color: ${black} !important;
    }
  `}
`;

const BlueButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${white};
  background-color: ${blue};
  border-color: ${blue};
  &:hover {
    color: ${white};
    background-color: #3d53a9;
    border-color: #394ea0;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 100, 191, 0.5);
  }
  &.disabled,
  &:disabled {
    color: ${white};
    background-color: ${blue};
    border-color: ${blue};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${white};
    background-color: #394ea0;
    border-color: #364996;
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 100, 191, 0.5);
  }
  ${isOutline && Outline(blue)}
`;

const GreenButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${white};
  background-color: ${green};
  border-color: ${green}; }
  &:hover {
    color: ${white};
    background-color: #529226;
    border-color: #4c8823;
  }
  &:focus, &.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 176, 46, 0.5);
  }
  &.disabled, &:disabled {
    color: ${white};
    background-color: ${green};
    border-color: ${green};
  }
  &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active, .show > &.dropdown-toggle {
    color: ${white};
    background-color: #4c8823;
    border-color: #477d21;
  }
  &:not(:disabled):not(.disabled):active:focus, &:not(:disabled):not(.disabled).active:focus, .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 176, 46, 0.5);
  }
  ${isOutline && Outline(green)}
`;

const OrangeButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${gray900};
  background-color: ${orange};
  border-color: ${orange};
  &:hover {
    color: ${gray900};
    background-color: #f38b2c;
    border-color: #f28520;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 159, 80, 0.5);
  }
  &.disabled,
  &:disabled {
    color: ${gray900};
    background-color: ${orange};
    border-color: ${orange};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${gray900};
    background-color: #f28520;
    border-color: #f27e14;
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 159, 80, 0.5);
  }
  ${isOutline && Outline(orange)}
`;

const PinkButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${gray900};
  background-color: ${pink};
  border-color: ${pink};
  &:hover {
    color: ${gray900};
    background-color: #e674a0;
    border-color: #e46998;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 148, 182, 0.5);
  }
  &.disabled,
  &:disabled {
    color: ${gray900};
    background-color: ${pink};
    border-color: ${pink};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${white};
    background-color: #e46998;
    border-color: #e25e91;
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 148, 182, 0.5);
  }
  ${isOutline && Outline(pink)}
`;

const RedButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${white};
  background-color: ${red};
  border-color: ${red};
  &:hover {
    color: ${white};
    background-color: #e6371a;
    border-color: #dc3318;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 85, 61, 0.5);
  }
  &.disabled,
  &:disabled {
    color: ${white};
    background-color: ${red};
    border-color: ${red};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${white};
    background-color: #dc3318;
    border-color: #d13017;
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 85, 61, 0.5);
  }
  ${isOutline && Outline(red)}
`;

const YellowButton = (isOutline: boolean) => css<StyledButtonProps>`
  color: ${gray900};
  background-color: ${yellow};
  border-color: ${yellow};
  &:hover {
    color: ${gray900};
    background-color: #ffad1e;
    border-color: #ffa811;
  }
  &:focus,
  &.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 187, 68, 0.5);
  }
  &.disabled,
  &:disabled {
    color: ${gray900};
    background-color: ${yellow};
    border-color: ${yellow};
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: ${gray900};
    background-color: #ffa811;
    border-color: #ffa404;
  }
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 187, 68, 0.5);
  }
  ${isOutline && Outline(yellow)}
`;

export const StyledButton = styled(Button)<StyledButtonProps>`
  border-radius: 0.125rem;
  box-shadow: none !important;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.125rem;
  margin-right: 1rem;
  padding: 1.0625rem 1.875rem 0.9375rem;
  text-transform: uppercase;
  ${(props) =>
    props.$buttonSize &&
    css`
      ${props.$buttonSize === "extraSmall" &&
      css`
        font-size: 0.5625rem;
        padding: 0.625rem 1rem 0.625rem;
      `}
      ${props.$buttonSize === "small" &&
      css`
        font-size: 0.625rem;
        padding: 0.9375rem 1.25rem 0.75rem;
      `}
    ${props.$buttonSize === "medium" &&
      css`
        font-size: 0.75rem;
        padding: 1.0625rem 1.875rem 0.9375rem;
      `}
    ${props.$buttonSize === "large" &&
      css`
        font-size: 0.8125rem;
        padding: 1.125rem 2.25rem;
      `}
    ${props.$buttonSize === "extraLarge" &&
      css`
        font-size: 1rem;
        padding: 1.375rem 2.75rem;
      `}
    `}

  &.btn.btn-warning, &.btn-orange, &.btn-pink, &.btn-yellow {
    color: ${white};
    &:focus {
      color: ${white} !important;
    }
  }

  &:focus {
    box-shadow: none;
  }

  ${(props) =>
    props.$smSize &&
    css`
      @media (min-width: 576px) {
        ${props.$smSize === "extraSmall" &&
        css`
          font-size: 0.5625rem;
          padding: 0.625rem 1rem 0.625rem;
        `}
        ${props.$smSize === "small" &&
        css`
          font-size: 0.625rem;
          padding: 0.9375rem 1.25rem 0.75rem;
        `}
      ${props.$smSize === "medium" &&
        css`
          font-size: 0.75rem;
          padding: 1.0625rem 1.875rem 0.9375rem;
        `}
      ${props.$smSize === "large" &&
        css`
          font-size: 0.8125rem;
          padding: 1.125rem 2.25rem;
        `}
      ${props.$smSize === "extraLarge" &&
        css`
          font-size: 1rem;
          padding: 1.375rem 2.75rem;
        `}
      }
    `}
  ${(props) =>
    props.$mdSize &&
    css`
      @media (min-width: 768px) {
        ${props.$mdSize === "extraSmall" &&
        css`
          font-size: 0.5625rem;
          padding: 0.625rem 1rem 0.625rem;
        `}
        ${props.$mdSize === "small" &&
        css`
          font-size: 0.625rem;
          padding: 0.9375rem 1.25rem 0.75rem;
        `}
      ${props.$mdSize === "medium" &&
        css`
          font-size: 0.75rem;
          padding: 1.0625rem 1.875rem 0.9375rem;
        `}
      ${props.$mdSize === "large" &&
        css`
          font-size: 0.8125rem;
          padding: 1.125rem 2.25rem;
        `}
      ${props.$mdSize === "extraLarge" &&
        css`
          font-size: 1rem;
          padding: 1.375rem 2.75rem;
        `}
      }
    `}
  ${(props) =>
    props.$lgSize &&
    css`
      @media (min-width: 992px) {
        ${props.$lgSize === "extraSmall" &&
        css`
          font-size: 0.5625rem;
          padding: 0.625rem 1rem 0.625rem;
        `}
        ${props.$lgSize === "small" &&
        css`
          font-size: 0.625rem;
          padding: 0.9375rem 1.25rem 0.75rem;
        `}
      ${props.$lgSize === "medium" &&
        css`
          font-size: 0.75rem;
          padding: 1.0625rem 1.875rem 0.9375rem;
        `}
      ${props.$lgSize === "large" &&
        css`
          font-size: 0.8125rem;
          padding: 1.125rem 2.25rem;
        `}
      ${props.$lgSize === "extraLarge" &&
        css`
          font-size: 1rem;
          padding: 1.375rem 2.75rem;
        `}
      }
    `}
  ${(props) =>
    props.$xlSize &&
    css`
      @media (min-width: 1200px) {
        ${props.$xlSize === "extraSmall" &&
        css`
          font-size: 0.5625rem;
          padding: 0.625rem 1rem 0.625rem;
        `}
        ${props.$xlSize === "small" &&
        css`
          font-size: 0.625rem;
          padding: 0.9375rem 1.25rem 0.75rem;
        `}
      ${props.$xlSize === "medium" &&
        css`
          font-size: 0.75rem;
          padding: 1.0625rem 1.875rem 0.9375rem;
        `}
      ${props.$xlSize === "large" &&
        css`
          font-size: 0.8125rem;
          padding: 1.125rem 2.25rem;
        `}
      ${props.$xlSize === "extraLarge" &&
        css`
          font-size: 1rem;
          padding: 1.375rem 2.75rem;
        `}
      }
    `}

  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "black" &&
    BlackButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "white" &&
    WhiteButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "blue" &&
    BlueButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "green" &&
    GreenButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "orange" &&
    OrangeButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "pink" &&
    PinkButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "red" &&
    RedButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "yellow" &&
    YellowButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "primary" &&
    PrimaryButton(props.$isOutline ?? false)}
  ${(props) =>
    props.$themeColor &&
    props.$themeColor === "secondary" &&
    SecondaryButton(props.$isOutline ?? false)}
`;
