// import Y2017 from "./2017.json"
// import Y2018 from "./2018.json"
// import Y2019 from "./2019.json"
// import Y2020 from "./2020.json"
// import Y2021 from "./2021.json"
// import Y2022 from "./2022.json"
// import Y2023 from "./2023.json";
import Y2024 from "./2024.json";

export const projects: { [k: number]: CurrentProject } = {
  2024: Y2024,
};

export interface CurrentProject {
  volunteerApplicationStartDate: string;
  volunteerApplicationEndDate: string;
  statistics: CurrentStatistics;
  sponsors: string[];
  schools: string[];
  schedule: { [key: string]: CurrentScheduleItem };
  history: { [key: string]: CurrentHistory };
  organizers: string[];
  forms: Forms;
}

export interface CurrentScheduleItem {
  date: string[];
}

export interface CurrentHistory {
  date: string[];
}

export interface CurrentStatistics {
  pyjamas: number;
  schoolCount: number;
  swimmingLessons: number;
}

export interface Forms {
  elf: string;
  eventVolunteer: string;
  funding: string;
}
