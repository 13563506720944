import styled from "styled-components";

export const Section = styled.section`
  padding: 80px 0px;
  @media (min-width: 768px) {
    padding: 110px 0;
  }
  @media (min-width: 992px) {
    padding: 140px 0;
  }
  @media (min-width: 1200px) {
    padding: 150px 0;
  }
`;