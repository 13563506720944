import { css } from "styled-components";

export const justifyContentCenter = css`
  -ms-flex-pack: center !important;
  justify-content: center !important;
`;

export const justifyContentBetween = css`
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
`;

export const alignItemsCenter = css`
  -ms-flex-align: center !important;
  align-items: center !important;
`;

export const noGutters = css`
  margin-right: 0;
  margin-left: 0;

  & > .col,
  & > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const textCenter = css`
  text-align: center !important;
`;

export const textJustify = css`
  text-align: justify !important;
`;
