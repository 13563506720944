import React from "react";
import styled, { css } from "styled-components";
import LogoSVMLutinGrey from "../assets/lutin-primary.png";
import { bgWhite, TitleMedium, TextExtraSmall } from "../styles/theme";
import { WithinQuery, Media } from "../styles/mediaQuery";
import { gray600, gray300, gray700 } from "../styles/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { fontAlt, fontWeight600 } from "../styles/fonts";
import { LetterSpacing1 } from "../styles/letter-spacing";
import intl from "react-intl-universal";

type Props = {};

export class Footer extends React.Component<Props> {
  render() {
    return (
      <StyledFooter>
        <div className="container">
          <div className="row footer-social">
            <div className="col-md-4 text-center text-md-left">
              <img src={LogoSVMLutinGrey} alt="" className="footer-logo" />
            </div>
            <div className="col-md-8 footer-icon-social">
              <ul>
                <li className="list-inline-item">
                  <a
                    title={intl.get("common.followUsFacebook")}
                    href="https://www.facebook.com/lutinsvm/"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    title={intl.get("common.followUsYouTube")}
                    href="https://www.youtube.com/channel/UCouGWw1krjBfz7--LyxaZGg"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    title={intl.get("common.followUsInstagram")}
                    href="https://www.instagram.com/lutinsvm/"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    title={intl.get("common.followUsTikTok")}
                    href="https://www.tiktok.com/@lutinsvm/"
                  >
                    <FontAwesomeIcon icon={faTiktok} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row footer-trademark">
            <div className="col-12">
              <div>
                <span>
                  &copy; {new Date().getFullYear()} Les Lutins -{" "}
                  {intl.get("common.slogan")}.
                </span>
              </div>
            </div>
          </div>
        </div>
      </StyledFooter>
    );
  }
}

const StyledFooter = styled.footer`
  padding-top: 3rem;
  padding-bottom: 3rem;
  ${bgWhite}

  .footer-social {
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 3rem;

    .footer-logo {
      max-height: 40px;
      opacity: 6;
    }

    .footer-icon-social ul {
      bottom: 10px;
      position: relative;
      margin-bottom: 0;
      margin-top: 1.5rem;
      padding: 0;
      text-align: center;
      ${TitleMedium}
      ${WithinQuery(
  Media.MD,
  css`
          text-align: right;
        `
)}

      li a {
        color: ${gray600};
      }
    }
  }

  .footer-trademark {
    -ms-flex-align: center;
    align-items: center;

    div {
      text-align: center;

      div {
        border-color: ${gray300};
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 3rem;

        span {
          text-transform: uppercase;
          color: ${gray700};
          font-family: ${fontAlt};
          ${fontWeight600}
          ${LetterSpacing1}
          ${TextExtraSmall}
        }
      }
    }
  }
`;
